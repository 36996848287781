
.sortable-container{
    max-width: 450px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    .add-label{
        background-color: var(--bb-bg-color-secondary);
        border-radius: 10px;
    }
}
.sortable-item {
    z-index: 9;
    overflow: hidden;
    background-color: var(--bb-bg-color-secondary);
    border: 1px solid var(--bb-bg-color-secondary);
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    box-shadow: none;
    border-radius: 10px;
    pointer-events: auto !important;
    max-width: 102px;
    max-height: 202px;
    :hover{
        cursor: grab;
    }
    i{
        text-align: center;
        padding: 4px;
        z-index: 999;
        position: absolute;
        top: 0;
        right: 0px;
        background: rgba(236, 21, 8, 0.8);
        width: 32px;
        border-radius: 0 10px;
        cursor: pointer !important;
        //font-size: 25px;
    }
}

.sort-status-filter{
    background: var(--bb-theme-color);
    border-radius: 5px;
    border: none;
    outline: none;
    width: auto !important;
    max-width: none !important;
    color: var(--bb-text-color-vendor);
    text-align-last: center;
}
.sort-remove-button{
    max-width: none !important;
}
.bb-dragging-dnd{
    z-index: 10;
}