
.react-toggle {
	touch-action: pan-x;
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
	&:hover {
		&:not(.react-toggle--disabled) {
			&:not(.react-toggle--checked){
				.react-toggle-track {
					background-color: var(--bb-bg-quaternary-color);
				}
			}
		}
	}
	&.react-toggle--checked{
		&.toggle-status{
			background: green;
			&.toggle-blue{
				background: var(--bb-blue-500); 
			}
			.react-toggle-track-check{
				left: 4px;
				bottom: 8px;
				visibility: visible !important;
			}
			.react-toggle-thumb{
				left: 21px !important;
			}
		}
		&.toggle-green{
			background: #6D9E3B;
			.react-toggle-track{
				border: 1px solid #A8D080;
			}
			.decarbonise-eye-icon *{ fill: #D3E7BF;}
			.measurement-icon *{ fill: #A8D080;}
			svg{ max-height: 11px; }
			&:hover {
				.react-toggle-track {
					background-color: var(--bb-green-700);
				}
			}
		}
		&.toggle-blue{
			&:hover {
				.react-toggle-track {
					background-color: var(--bb-blue-600);
				}
			}
		}
	}
	&.toggle-status{
		background: red;
		&.toggle-blue{
			background: unset;
		}
		border-radius: 10px;
		max-width: 40px !important;
		max-height: 23px !important;
		.react-toggle-track{
			max-width: 40px !important;
			max-height: 20px !important;
		}
		.react-toggle-thumb{
			width: 16px !important;
			height: 14px !important;
			left: 4px;
		}
		.react-toggle-screenreader-only{
			display: none;
		}
		.react-toggle-track-x{
			left: 25px;
    		bottom: 10px;
		}
		.react-toggle-track-check{
			visibility: hidden;
		}
	}
	
	&.toggle-green{
		background: unset;
	}
	&.bb-toggle, &.toggle-green {
		.react-toggle-track { border: 1px solid #CACBD333 }
	}
}
.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}
.react-toggle-track {
	width: 96px;
	height: 34px;
	padding: 0;
	border-radius: 30px;
	background-color: #aeaeae36;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
	border: 0.5px solid white;
}
.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 9px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	font-size: 20px;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}
.react-toggle--checked {
	.react-toggle-track-check {
		opacity: 1;
		-webkit-transition: opacity 0.25s ease;
		-moz-transition: opacity 0.25s ease;
		transition: opacity 0.25s ease;
	}
	.react-toggle-track-x {
		opacity: 0;
	}
	.react-toggle-thumb {
		left: 53px;
	}
}
.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 10px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 34px;
	opacity: 1;
	font-size: 20px;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}
.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 3px;
	left: 6px;
	width: 37px;
	height: 28px;
	border: 1px solid #4D4D4D;
	border-radius: 39%;
	background-color: #FAFAFA;;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}
.swipe-creative-specs {
	.react-toggle-thumb {
		background-color: var(--bb-color-secondary);
	}
	&.nexd{
		.react-toggle-track-check{
			font-size: 14px;
			top: 11px;
		}
		.react-toggle-track-x{
			font-size: 14px;
			top: 11px;
		}

	}
}

.bb-toggle_primary{
	.react-toggle-thumb {
		background-color: var(--bb-theme-color);
	}
}

