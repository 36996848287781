@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  @include animation-name("fadeIn");  
}

@include keyframes(fadeOut) {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  @include animation-name("fadeOut");  
}

@include keyframes(slideInLeft) {
  from {
    @include translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    @include translate3d(0, 0, 0);
  }
}

.slideInLeft {
  @include animation-name("slideInLeft");
}

@include keyframes(slideInRight) {
  from {
    @include translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    @include translate3d(0, 0, 0);
  }
}

.slideInRight {
  @include animation-name("slideInRight");
}

.bb-animate{
  @include animation-settings("1s", "both");
}

@include keyframes(swing) {
  20% {
    @include rotate3d(0, 0, 1, 15);
  }

  40% {
    @include rotate3d(0, 0, 1, -10);
  }

  60% {
    @include rotate3d(0, 0, 1, 5);
  }

  80% {
    @include rotate3d(0, 0, 1, -5);
  }

  to {
    @include rotate3d(0, 0, 1, 0);
  }
}

.swing {
  @include transform-origin(top center);
  @include animation-name("swing");  

}


@include keyframes(floating) {
from { transform: translate(0,  0px); }
65%  { transform: translate(15px, 0px); }
to   { transform: translate(0, 0px); }    
}

.floating {  
animation-name: floating;
animation-duration: 5s;
animation-iteration-count: infinite;
animation-timing-function: ease-in-out;
}


.item-enter {
opacity: 0;
}
.item-enter-active {
opacity: 1;
transition: opacity 500ms ease-in;
}
.item-exit {
opacity: 1;
}
.item-exit-active {
opacity: 0;
transition: opacity 500ms ease-in;
}


@-webkit-keyframes zoomIn {
from {
  opacity: 0;
  -webkit-transform: scale3d(0.3, 0.3, 0.3);
  transform: scale3d(0.3, 0.3, 0.3);
}

50% {
  opacity: 1;
}
}
@keyframes zoomIn {
from {
  opacity: 0;
  -webkit-transform: scale3d(0.3, 0.3, 0.3);
  transform: scale3d(0.3, 0.3, 0.3);
}

50% {
  opacity: 1;
}
}
.zoomIn {
-webkit-animation-name: zoomIn;
animation-name: zoomIn;
}