
.buddy-builder-circle{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    font-size: 14px;
    color: $bb-text-color-black;
    line-height: 65px;
    text-align: center;
    background: $bb-color-white;
    font-weight: 700;
    margin-right:10px;
    
    &:hover{
        color: var(--bb-text-color-vendor);
        background: $bb-theme-color;
        cursor: pointer;
    }
  
    &.active{
        color: var(--bb-text-color-vendor);
        background: $bb-theme-color;
  }
}

/* Deal tables */

.deal-table-preview {
    max-width: 800px;
    table, td {
      border-bottom: 1px solid #ddd;
      text-align: left;
    }
    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 14px;
    }
    th {
      padding: 10px;
    }
    td {
      padding: 10px;
      background: $bb-bg-secondary-color;
      color: $bb-text-color-black;
    }
    input {
      font-size: 14px;
    }
  }