.bb-row-select{
    background: #ffffff;
    border: none;
    outline: none;
    width: auto;
    max-width: 170px;
    color: #000000;
    text-align-last: center;
    height: 34px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}