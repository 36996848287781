.bb-login-form{
    position:relative;
    left: 150px;
    width: 300px;

    @media only screen and (max-width: 600px) {
        left: 0;
        &-container{
            justify-content: center;
            background-image: none;
        }
    }

    &-container{
        background: url('~/public/images/auth/login_bg_min.jpg');
        background-size: cover;
        background-position: left;
        background-attachment: fixed;
    }

    .logo{
        width: 100%;
        height: 93px;

        background-repeat: no-repeat;
        background-image: var(--bb-small-logo);
        background-size: contain;
        &.enterprise{
            background-image: var(--bb-small-logo-enterprise);
        }
    }
    .input-container{
        height: 58.5px;
    }
    .spacer{
        height: 39.5px;
    }
     input{
        border:none;
        border-bottom:1px solid $bb-theme-color;
        border-radius: 8px;
        font-size: 14px;
        
        &:focus{
            outline: none;
            box-shadow: none;
            border-color: $bb-bg-color;
        }
    }

    .required{
        visibility: visible;
        padding: 10px;
        font-size: small;
        color: $bb-error-color;
        font-weight: bold;
        display: unset;
    }

    .required-nt{
        visibility: hidden;
        display: unset;
        padding: 10px;
    }
    
    .bb-signin-btn{
        color: var(--bb-text-color-vendor);
        border: none;
        padding: 4px 35px;
        border-radius: 35px;
        cursor: pointer;
        &:disabled{
            background-color: $bb-disabled-color;
        }
    }
      
} 

/* Underline when hover */
.hvr-underline-from-center:hover{
    transition: all 1s;
    text-decoration: underline;
    text-decoration-color: $bb-theme-color;
    cursor: pointer;
}


.bb-logo-buddy{
    position: absolute;
    width: 120px;
    height: 120px;
    background: white;
    color: $bb-text-color-black;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    font-size: 28px;
    left: 46%;
    top: -3%;
    span{
        align-self: center;
    }

    &-mini{
        position: absolute;
        width: 50px;
        height: 50px;
        background: white;
        color: $bb-text-color-black;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        font-size: 14px;
        left: 46%;
        top: 3%;
        span{
            align-self: center;
        }
    }
}

.bb-logo-desc{
    /* position: absolute;
    bottom: 40px; */
    position: absolute;
    bottom: 20px;
    left: 100px;
}

.bb-buddy-logo-small{
    /* width: 150px;
    height: 100%; */

    background-repeat: no-repeat;
    width: inherit;
    height: 50px;
    background-image: var(--bb-small-logo);
    background-size: contain;
    // background-position: center;
    &-mobile{
        width: 175px;
        height: 50px;
    }
    margin-left: 10px;

    &.unified-rewards{
        height: 42px;
        background-image: var(--bb-small-logo-unified);

    }
    &.unified-discount{
        height: 42px;
        background-image: var(--bb-small-logo-unified);

    }
    &.independent{
        height: 42px;
        background-image: var(--bb-small-logo-independent);

    }
    &.enterprise{
        height: 42px;
        background-image: var(--bb-small-logo-enterprise);
    }
}

.bb-forgot-password{
    color: #808080;
    &:active, &:visited{
        color: #808080;
    }
}

.bb-signup{
    a:hover{
        color: #fff;
    }
}