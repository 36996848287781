body,
html,
#root {
  height: 100%;
  width: 100%;
  font-family: $bb-primary-font, sans-serif;
  font-style: normal;
  font-weight: 400;
  background-color: $bb-bg-color;
  color: $bb-text-color-white;
}

a {
  color: var(--bb-text-color-white);
  text-decoration: none;
}