

.bb-color-picker{
    &-bg-container{
        border-radius: 10px;
        // box-shadow: 2px 2px 10px 1px var(--bb-color-gray);
        margin-bottom: 0.25rem !important;
        height: 38px;
        background: var(--bb-color-white);
        margin-right: 10px;
        width: 44px;
        cursor: pointer;
        border: 1px solid white;
    }
    &-bg{
        position: relative;
        height: 100%;
        margin-bottom: 0;
        border-radius: 10px;
        width: 100%;
    } 
    //.popup-content {
        .sketch-picker{
            width: 210px !important;
            box-shadow: none !important;
        }
    //}
    .close-picker{
        background: var(--bb-theme-color);
        padding: 0px 10px;
        text-align: center;
        color: var(--bb-text-color-gray);
        cursor: pointer;
    }
    &-cover{
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: rgba(0,0,0,0.5);
    }
    &-popover{
        position: fixed;
        z-index: 99;
        /* top: 44px;
        background: white;
        border-radius: 4px; */
        top: 0;
        border-radius: 4px;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .bb-popup-cp{
        &-arrow{
            background: var(--bb-theme-color);
        }
    }
    &-container{
        width: max-content;
        top: 50%;
        left: 50%;
        position: relative;
        transform: translate(-50%,-50%);
        background: white;
        display: flex;
        flex-direction: column;
    }
    &-button-container{
        padding: 0 10px;
    }
}

