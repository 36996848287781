@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
      }
    @keyframes #{$animation-name} {
      @content;
    }
  }

  
  @mixin animation-name($str) {
    -webkit-animation-name: #{$str};
    animation-name: #{$str};   
  }

  @mixin animation-settings($duration, $fillmode){
    -webkit-animation-duration: #{$duration};
    animation-duration: #{$duration};
    -webkit-animation-fill-mode: #{$fillmode};
    animation-fill-mode: #{$fillmode};
  }

// generic transform
@mixin transform($transforms, $isImportant: false) {
  @if $isImportant{  
    -moz-transform: $transforms !important;
    -o-transform: $transforms !important;
    -ms-transform: $transforms !important;
    -webkit-transform: $transforms !important;
    transform: $transforms !important;
  }
  @else{
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
  }
}
// rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}
@mixin rotate3d($x, $y, $z, $deg) {
    @include transform(rotate3d(#{$x}, #{$y}, #{$z}, #{$deg}deg));
}
// scale
@mixin scale($scale) {
  @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y, $isImportant: false) {
    @include transform(translate($x, $y), $isImportant);
}
@mixin translateX($x, $isImportant: false) {
    @include translate($x, 0, $isImportant);
}
@mixin translate3d ($x, $y, $z) {
    @include transform(translate3d($x, $y, $z));
}
// skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin filter($filter){
    -webkit-filter: $filter;
    -moz-filter: $filter;
    filter: $filter;
}