@media (max-width: 577px){
    .bb-container-main{ padding-bottom: 70px; }
    // ad and deal builder form
    .bb-create-deal > .pr-2{ padding-right: 0px !important; }
    .bb-create-creative > form {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
        & > .pr-4{ padding-right: 0 !important; }
    }
    div.fix-button-overlap{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding-bottom: 10px;
        flex-direction: column;
        &, & button{
            width: 100%;
            max-width: -webkit-fill-available;
        }
        & button { margin-right: 0 !important; }
        > .d-flex{
            flex-direction: column;
        }
    }
    .bb-builder-buttons .fix-button-overlap{
        margin-left: 0;
        margin-right: 0;
    }
    // filter in choose media
    .mo-filter{
        & > div{
            flex-direction: column !important;
        }
        .bb-search-input-container, .bb-search-input{ width: 100% !important; }
        .bb-search-input{ margin-top: 12px !important; }
        .bb-status-filter-container{
            width: 100%;
            flex-wrap: wrap;
        }
        .bb-status-filter{
            flex-grow: 1;
            margin-right: 0 !important;
            margin-left: 0 !important;
            &-text{ margin-left: 0 !important; margin-right: 0.5rem!important; }
            .rs-picker-input{ width: 100% !important; max-width: unset !important; }
        }
    }

    .bb-creative-info-box{
        padding: 10px 5px !important;
    }
    .device-preview-container, .creative-preview-container{
        transform: scale(0.7);
        // negative values to fix gap from scale
        top: -70px;
        left: -10px;
        margin-bottom: -100px !important;
        .preview-iframe-container{ flex-wrap: unset !important; }
    }

    .min-height_194px{ height: auto !important;}
    .bt-table-paginations-container{ gap: 10px 15px; }

    //pages
    .bb-brief-env{ margin-bottom: 0.5rem; }
    .my-deals-page{
        .my-ads-cards-container{
            max-width: -webkit-fill-available !important;
            margin-right: 1rem;
            flex-grow: 1;
            .bb-ad-title{ max-width: calc(100% - 40px); }
            .bb-ad-status .flex-row{ flex-wrap: wrap; }
        }
        .my-ads-cards{
            width: unset !important;
            justify-content: center;
        }
    }
    .my-deals-page .bb-all-filter-container, .mo-filters{
        width: 100%;
        .rs-picker-input{ width: unset !important; max-width: unset !important; }
        & > *, .bb-checkbox-filter, .bb-status-filter, .bb-search-input, .bb-search-input-container{ flex-grow: 1; margin-left: 0 !important; margin-right: 0 !important;}
        .bb-search-input-container{ display: flex; }
        .bb-checkbox-filter .bb-checkbox-rs, .user-select.mw-250 .bb-select__control, .user-select.mw-350 .bb-select__control{ min-width: unset !important; }
        .user-select.mw-350 .bb-select__control{max-width: unset !important;}
        .bb-month-picker .title-wrapper{
            width: unset;
            min-width: 200px;
        }
    }
    .showcase-page{
        .showcase-cards-container{ flex-grow: 1; margin-right: 1rem;}
        .showcase-cards{ align-self: center; }
    }

    .help-page-container{
        button{ width: 100% !important; }
        .speech-bubble{ width: auto !important;}
    }
    .bb-tcs-container{ max-width: 100%; }

    .change-user-info button{ max-width: unset !important; }

    .bb-format-container .kpi-select{
        right: 0 !important;
        padding: 0 1.5rem;
    }
    .slick-slider .slide-container.activeSlide{ left: 0 !important; }
    .bb-builder-buttons{
        flex-direction: column;
        // for back button
        > .bb-button{
            max-width: unset;
            margin-right: 0 !important;
        }
    }

    //common components
    .react-confirm-alert-overlay{ padding: 15px; }
    .bb-modal-container{ margin-top: 40px; }
    .bb-modal-header{ position: absolute; }
    .slider-card-scroll-curated{
        padding-top: 15px;
        .bm-slider-deal-image{ padding-left: 1rem !important; padding-right: 1rem !important; }
    }

    .bb-search-input{ padding: 0.5rem 0.5rem 0.5rem 28px !important;}
    .bt-table-page-input, .bt-table-paginations-buttons{ margin-bottom: 5px; }
    .green-fleet-container{
        .description{ display: flex; align-items: center; }
    }
    .speech-bubble{
        height: auto !important;
        padding: 2px 5px;
    }
    .bb-button{
        white-space: break-spaces !important;
        place-content: center;
        display: flex;
        align-items: center;
        &.bb-button-info { width: 100% !important; }
    }
    .report-page .date-picker-bb-button{ height: 31px !important; }
    .live-date-container{
        margin-left: 0 !important;
        .date-picker-bb-button{ height: 100% !important; }
    }
    .timezone-select{
        width: unset !important;
    }
    .date-range-container{
        min-width: unset !important;
        max-width: -webkit-fill-available !important;
    }
    .report-container {
        .summary-card {
            margin-right: 0 !important;
            width: 100% !important;
        }
        
    }


    // reusable css
    .mo{
        &-w-100, &-col > *{
            width: 100% !important;
            max-width: unset !important;
        }
        &-wrap{ flex-wrap: wrap;}
        &-col{
            flex-direction: column !important;
        }
        &-grow, &-input .bb-search-input-container{
            display: flex;
            &, & > *{ flex-grow:1 }
        }
        &-email-button{
            display: flex;
            flex-direction: column;
            .email-button{ flex-grow: 1; height: auto !important;}
        }
        &-p-0{
            padding: 0 !important;
        }
    }
}
//custom media width
@media (max-width: 348px){
    .my-deals-page{
        .bb-all-filter-container{
            .bb-status-filter{ margin-right: 0 !important; margin-left: 0 !important; }
            .bb-status-filter-text{ margin-top: 0 !important; }
        }
    }
}
@media (max-width: 480px){
    #chatlio-widget .chatlio-title-bar.chatlio-title-bar-button.chatlio-bottom-right {
        right: 12px;
        bottom: 20px;
    }
}
@media (min-width: 403px) and (max-width: 577px){
    .bespoke-status-filter, .mo-filter > span{ margin-bottom: 12px; }
}
@media (max-width: 626px){
    // filter in choose media
    .mo-filter{
        flex-direction: column !important;
        & > span{ align-self: start !important; }
        & > div{
            width: 100% !important;
            justify-content: space-between !important;
        }
        &.bespoke-filter{
            .bb-search-input-container, .bb-search-input{ width: 100% !important; }
        }
    }
}

@media (max-width: 769px){
    .dashboard-card-container{ position: relative !important; }
    .mo-mb-gap > * { margin-bottom: 0.5rem; }
    // nav ad-builder
    div.nav-parent{
        overflow-x: auto;
    }
    // ad and deal builder form
    div.fix-button-overlap{ 
        padding-right: 0px !important;
        padding-left: 0;
    }
    .help-page-container{
        .help-paragraph{
            padding: 0; 
            max-width: unset;
            flex: unset;
        }
    }
    .options-card-container-edit .options-card{ margin-bottom: 0.5rem; }
    .bb-modal-container{
        max-height: 85vh;
        overflow: auto;
    }
    .chart-container{
        overflow: auto;
        min-width: unset !important;
        .fusioncharts-container{
            min-width: 400px;
            max-width: 900px;
        }
    }
    .date-picker{
        position: relative;
        .date-picker-bb-button{
            height: 100% !important;
        }
        .date-picker-input{ min-width: unset; }
    }
}
//custom media width

@media (max-width: 993px){
    //my-deals/creatives?ad_id page
    .creative-image-container {
        background-color: transparent;
        &, .bb-creative-info-image-box{
            width: 100% !important;
            max-width: 100% !important;
        }
    }

}

.creative-image-container{
    align-self: center;
    .bb-creative-info-image-box{ display: flex; justify-content: center; padding: 0 15px;}
}