.bb-container-main {
  left: 250px;
  margin: 0;
  position: absolute;
  flex-grow: 1;
  transition: left 0.5s;
  width: calc(100% - 250px);
  height: 100%;  
  // overflow: auto;
  scroll-behavior: smooth;
  position: relative;
}

@media only screen and (max-width: 699px) {
  @supports (-webkit-overflow-scrolling: touch) {
    .bb-container-main {
      position: relative;
    }
  }
}

.creative-dot-yellow {
  border: none;
  border-top: 2px dotted var(--bb-color-secondary);
  color: transparent;
  background-color: transparent;
  height: 1px;
  width: 100%;
}

#creative-frame {
  -webkit-filter: drop-shadow(0px 5px 10px black);
  filter: drop-shadow(0px 5px 10px black);
  width: 340px;
  border-radius: 52px;
  height: 682px;
}

.preview{
  &-iframe-container {
    min-width: 300px;
    max-width: 400px;
  }
  &-url-container {
    max-width: 400px;
    height: 450px;
  }
}

@media only screen and (min-width: 1200px) {
  .mx-h-1300 {
    max-height: 1300px;
  }
  .creative-frame-add{
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
  }
}

.right-corner {
  position: absolute;
  right: 0;
  bottom: 12px;
}
.min-height-720 {
  min-height: 720px;
}

.iframe-desktop {
  /* height: 1080px !important;
  width: 1920px !important; */
  height: 324px !important;
  width: 576px !important;
  position: absolute;
  top: 17px;
  left: 17px;
  border-radius: 0px !important;
  /* transform: scale(0.30);
  transform-origin: top left; */
  &-skin{
    height: 1080px !important;
    width: 1920px !important;
    transform: scale(0.30);
    transform-origin: top left;
    position: absolute;
    top: 17px;
    left: 17px;
    border-radius: 0px !important; 
  }
  &-zoom{
    height: 1080px !important;
    width: 1920px !important;
    transform: scale(0.68) translate(-50%, -50%);
    transform-origin: top left;
    position: absolute;
    border-radius: 0px !important;
    max-height: 111vh !important;
    max-width: 140vw !important;
    left: 50%;
    top: 50%;
  }
  &-image {
    max-width: 1527px !important;
    /* width: 100% !important; */
    height: 1237px !important;
    border-radius: 28px !important;
    transform: scale(0.4);
    transform-origin: top center;
    align-self: start;
  }
  &-container{
    width: 611px;
    height: 495px;
    position: relative;
  }
}

#ad-preview-spinner {
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.hover-light-pink:hover {
  background: rgba(255, 255, 255, 0.1);
}

.bb-bg-approve {
  background: rgb(51, 136, 17);
}

.mw-900 {
  max-width: 900px !important;
}

.bg-modal{
  &-header { background: var(--bb-bg-secondary-color) !important; }
  &-slider { background: white !important; }
}

.bm-logo-deal-slider {
  position: absolute;
  left: -31px;
  top: -22px;
  &.deal {
    height: auto;
    width: 542px;
    left: -162px !important;
    top: 19px;
  }
  &.logo {
    opacity: 0.6;
    top: 36px;
    filter: drop-shadow(10px 11px 4px black);
  }
}

.bm-logo-c-deal-slider.deal {
  height: auto;
  width: 100%;
  padding-top: 8px;
  padding-right: 5px;
  /* position: absolute; */
}
.emission-modal{
  line-height: 27px;
  .carousel-container{ height: 610px; }
  &-image{
    width: 191px;
    height: auto;
    max-height: 397px;
  }
}
.bb-card-list {
  -webkit-columns: 200px 5;
  -moz-columns: 200px 5;
  columns: 200px 5;
  padding-left: 16px;
  &.nopoint {
    list-style-type: none;
    padding: 0px;
  }
}

.bb-card-footer-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: var(--bb-bg-secondary-color);
  border-radius: 0px 10px 10px;
}
.max-height_194px{
  max-height: 194px;
  overflow: auto;
}

.bm-slider-deal-image {
  position: static;
  overflow: hidden;
  width: 100%;
    &.projects{
      div:first-of-type{
        overflow: hidden;
      }
      img{
        height: 100%;
        width: auto;
        position: absolute;
      }


    }
  div:first-of-type{
    width: 100%;
    height: calc(100% - 42px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bm-slider-adv-logo-big{
  width: 160px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.slider-card-scroll {
  height: calc(100% - 166px);
  overflow-y: auto;
}

.bb-slider-footer-right-side {
  position: absolute;
  right: 0;
}

.bb-slider-footer-left-side {
  position: absolute;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .bm-logo-deal-slider {
    display: none;
  }
}

@media only screen and (max-width: 602px) {
  .slider-card-scroll {
    height: calc(100% - 187px);
  }
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 1px #ccc;
}

.max-w-500 {
  max-width: 500px !important;
}

.max-w-510 {
  max-width: 510px;
}
.rotate-45deg{
  transform: rotate(-45deg);
}

.standards{
  max-height: 40px;
  width: auto;
}