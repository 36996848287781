
  
  @mixin rings($duration, $delay) {
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0;
      bottom: 0;
      content: '';
      height: 100%;
      width: 100%;
      border: 16px solid rgba(232, 52, 87,0.7);
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: $duration;
      animation-delay: $delay;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(.65,0,.34,1);
      z-index: 0;
  }
  
  .bb-spinner-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background: #E83457;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
    
    span {
      position: relative;
      z-index: 2;
      img{
        border-radius: 50%;
        height: 100px;
        width: 100px;
      }
    }
    
    &::after {
      @include rings(2s, 0s);
    }
    
    &::before {
      @include rings(2s, 0.5s);
    }
  }
  
  
  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale3d(0.75,0.75,1);
    }
    
    to {
      opacity: 0;
      transform: scale3d(2,2,1);
    }
  }
  
  