@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.bb-tcs-container-2 {
    height: 100%;
    max-height: 180px;
    overflow: auto;
    border-radius: 5px;
    width: 680px;
    font-size: 14px;
}

.bb-form-input-label-2 {
    min-width: 100px;
    text-align: right;
}

.bb-form-commercials, .bb-form-commercials-calc{
    .form-control.disabled, .form-control:disabled, .date-picker-input.disabled {
        background: transparent !important;
        color: var(--bb-primary-text-2);
        border: 1px solid hsl(0, 0%, 90%) !important;
    }

    .bb-select--is-disabled, .bb-select__control--is-disabled, .date-picker-input.disabled input{
        background: transparent !important;
        color: var(--bb-primary-text-2); 
        
        .bb-select__single-value{
            color: var(--bb-primary-text-2) !important;
        }
        .bb-select__indicators{
            display: none;
        }
    }

    .prefix-container{
        .disabled{
            background: transparent !important;
            color: var(--bb-primary-text-2);
            border: 1px solid hsl(0, 0%, 90%) !important;
        }
    }
}


.bb-discount-calculate-button{
    position: absolute;
    top: 165px;
    right: 20px;
}

.ml--20 {
    margin-left: -20px;

}

.h-300{
    height: 300px;
}

.h-470{
    height: 470px;
}

.date-range-container {
    min-width: 400px;
    max-width: 500px;
    position: absolute;
    right: unset;
    overflow: hidden;
    border-radius: 7px;
    border: 1px solid #D9D9D9;
    box-shadow: 2px 2px 8px rgba(32, 33, 36, 0.03);


    .bg-whitesmoke {
        background: whitesmoke;
    }

    .border-radius-top {
        border-radius: 10px 10px 0px 0px;
    }

    .border-radius-bottom {
        border-radius: 0px 0px 10px 10px;
    }
}

.date-picker-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    margin-top: 0;
    left: 0px;
    background: rgba(0, 0, 0, 0.1);
}

.script-modal {
    font-family: 'Ubuntu';
    
    .bb-form{
        overflow: auto;
        max-height: 700px;

        &::-webkit-scrollbar {
            width: 10px;
            /* Total width including `border-width` of scrollbar thumb */
            height: 0;
            box-shadow: inset 0 0 50px var(--bb-bg-color-tertiary);
            border-radius: 5px;
    
            &-thumb {
                height: 20px;
                border: 2px solid rgba(0, 0, 0, 0);
                /* Transparent border together with `background-clip: padding-box` does the trick */
                background-clip: padding-box;
                -webkit-border-radius: 0.5;
                background-color: var(--bb-theme-color);
                border-radius: 5px;
            }
    
            &-track {
                border-radius: 5px;
                padding: 5px;
            }
        }
    }
}

.bb-ad-top {

    border: 1px solid;
    border-color: hsl(0, 0%, 80%);
    border-bottom: none;
    padding-top: 10px;
    border-radius: 10px 10px 0px 0px;
    padding-bottom: 10px;

}

.bb-ad-bottom {
    border: 1px solid;
    border-color: hsl(0, 0%, 80%);
    border-top: none;
    padding-bottom: 10px;
    border-radius: 0 0 10px 10px;
}

.bb-ad-side {
    border: 1px solid;
    border-color: hsl(0, 0%, 80%);
    border-top: none;
    border-bottom: none;
    padding-bottom: 10px;
    border-radius: 0;
}

.bb-ad-all-border {
    border: 1px solid;
    border-color: hsl(0, 0%, 80%);   
    border-radius: 10px;
}


.no-data-bg {
    background: var(--bb-bg-color-quaternary);
    min-height: 249px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.options-card-container {
    font-size: 14px;

    .bespoke-filter,
    .prebuilt-filter {
        div:last-child {
            gap: 8px;
        }

        .bb-status-filter {
            margin-right: 0px !important;
        }

        .bb-search-input {
            height: 35px;
        }
    }

    .profile-name-size label {
        margin-right: 14px;
    }

    .info-box {
        padding: 9px;
        align-items: flex-start;
        gap: 4px;
        background: var(--bb-bg-color-tertiary);
        border-radius: 50px;
        font-size: 12px;
        display: inline-flex;
    }

    hr {
        background: var(--bb-bg-color-tertiary-20);
    }

    .slider-image-item {
        max-width: 230px;
        min-width: 230px;
    }

    .options-card-img-container {
        width: 198px;
        height: 119px;
        overflow: hidden;
        position: relative;
        transition: all 1s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }

        &.category {
            img.img-card {
                width: auto;
                height: 100%;
            }

        }

        img.img-card {
            width: 198px;
            height: auto;
            align-self: center;
            transition: all 1s ease-in-out;

            &-co2 {
                position: absolute;
                width: 30px;
                top: 24px;
                left: 12px;
                z-index: 2;
            }
        }

        &:hover .tooltiptext {
            opacity: 1;
        }

        .tooltiptext {
            opacity: 0;
            width: 100%;
            max-width: 298px;
            height: 150px;
            background-color: rgba(0, 0, 0, 0.8);
            color: #fff;
            text-align: left;
            padding: 15px;
            position: absolute;
            z-index: 1;
            font-size: 14px;
            transition: 1s all ease-in-out;
            cursor: pointer;
        }
    }

    .options-card {
        width: 200px;
        height: auto;
        background: var(--bb-bg-color-quaternary);
        border: 1px solid var(--bb-color-white-secondary);
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        user-select: none;
        margin-right: 30px;

        &.bespoke-button {
            min-height: 238px;
        }

        .customised-icon,
        .card-dercarbonised-icon {
            position: absolute;
            right: 18px;
            top: 35px;
        }

        &-decarbonise {
            animation: fadein 0.5s ease-in;
        }

        &.max-height {
            max-height: 216px !important;
        }

        &.not-selected-card {
            opacity: 0.65 !important;
        }

        &-selected {
            &.options-card-decarbonise {
                border: 1px solid var(--bb-green-600) !important;

                .options-selected {
                    background: var(--bb-green-600);
                    border: 1px solid var(--bb-green-600) !important;
                }
            }

            border: 1px solid var(--bb-theme-color) !important;
        }

        &-app {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background: var(--bb-theme-color);
            color: var(--bb-text-color-vendor);
            padding: 3px 5px;
        }

        &-adv-logo {
            width: 76px;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }

    .options-title-container,
    .options-card-summary {
        padding: 6px;
        font-size: 12px;
    }

    .options-title {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
    }

    .options-not-selected {
        background: #D5D5D5;
        color: var(--bb-text-color-black);
        font-weight: bold;
        text-align: center;
        padding: 6px 0px;
        border: 1px solid #D5D5D5;
        border-radius: 0 0 3px 3px;
    }

    .options-selected {
        background: var(--bb-theme-color);
        color: var(--bb-text-color-vendor);
        font-weight: bold;
        text-align: center;
        padding: 6px 0px;
        border: 1px solid var(--bb-theme-color) !important;
    }

    .carousel-button-group {
        position: relative;
    }

    .custom-carousel__arrow {
        position: absolute;
        outline: none;
        transition: all 0.5s;
        border-radius: 35px;
        z-index: 96;
        border: none;
        background: rgba(0, 0, 0, 0.5);
        min-width: 43px;
        min-height: 43px;
        opacity: 1;
        cursor: pointer;

        &::before {
            font-size: 20px;
            color: var(--bb-text-color-white);
            display: block;
            font-family: revicons;
            text-align: center;
            z-index: 2;
            position: relative;
            top: 5px;
            font-style: normal;
        }

        &--left {
            left: -20px;
            top: -150px;

            &::before {
                content: "\E824";
            }
        }

        &--right {
            right: -20px;
            top: -150px;

            &::before {
                content: "\E825";
            }
        }
    }
}

.bespoke-button {
    min-width: 200px;

    div {
        margin-bottom: 16px;
        margin-top: 10px;

        i {
            font-size: 90px;
            color: var(--bb-bg-color-tertiary-20);
        }
    }

    span {
        font-size: 16px;
    }

}

.bespoke-arrows {
    position: absolute !important;
    width: 100% !important;

    .custom-carousel__arrow--left {
        top: 108px !important
    }

    .custom-carousel__arrow--right {
        top: 109px !important;
        right: 20px !important;
    }
}

.bespoke-customised {
    &:not(.no-border) {
        border: 1px solid var(--bb-theme-color-secondary);
    }
}

.options-card-decarbonise {
    border-color: var(--bb-green-600) !important;
}

.project-supported-container {
    img {
        height: 35px;
        margin-top: 32px;
        margin-bottom: 16px;
    }
}

.emissions-form {

    .options-card-container .options-title-container,
    .options-card-container .options-card-summary {
        padding: 10px;
    }

    .company-card {
        height: 200px !important;

        .options-title-container img {
            height: 22px;
        }
    }

    .offset-options-card {
        height: 129px !important;
    }
}

.space-normal {
    white-space: normal !important;
}

.options-card-container {
    &.green {
        .options-selected {
            background-color: var(--bb-green-600) !important;
            border: 1px solid var(--bb-green-600) !important;
        }

        .options-card-selected {
            border: 1px solid var(--bb-green-600) !important;

        }

        .options-card {
            &.providers {
                height: 200px !important;

                hr {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.max-height_470px {
    overflow: auto;
    max-height: 470px;

    &::-webkit-scrollbar {
        width: 1.00em;
        /* Total width including `border-width` of scrollbar thumb */
        height: 0;
        box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
        //border-radius: 10px;
        margin-right: 10px;

        &-thumb {
            height: 1em;
            border: 0.3em solid rgba(0, 0, 0, 0);
            /* Transparent border together with `background-clip: padding-box` does the trick */
            background-clip: padding-box;
            -webkit-border-radius: 0.5;
            background-color: var(--bb-bg-color-tertiary-20);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
        }

        &-track {
            -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 1);
            //border-radius: 10px;
            padding: 10px;
        }
    }
}

/* Typing Animation */
.typing-effect {

    width: 400px;
    //display: inline-block;
    align-self: center;
    border-right: 0.15em solid #fff;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 1s steps(15, end) 1s 1 normal both,
        removeCursor 0.1s 2s forwards;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 400px;
    }
}

@keyframes removeCursor {
    to {
        border-right: none;
    }
}

/*End Typing Animation */

/* Selection sub menu */


@keyframes topToBottom {
    0% {
        opacity: 0;
        transform: rotateX(-90deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: -1rem;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.selection-box-unified-container {
    //.selection-box-unified {
        &:hover {
            .selection-drop-menu {         

            .selection-drop-item {
                visibility: visible;
            

                //perspective: 1000px;
             
                    opacity: 0;
                    transform-origin: top center;

                    @for $i from 1 through 2 {
                        &:nth-child(#{$i}) {
                            animation-name: topToBottom;
                            animation-duration: 400ms;
                            animation-delay: (150ms * $i) - 300;
                            animation-timing-function: ease-in-out;
                            animation-fill-mode: forwards;
                        }
                    }
                
            }
        }

    }
    //}
    .selection-drop-menu {
        .selection-drop-item {
            visibility: hidden;

            font-size: 14px !important;
            padding: 15px 15px 15px;
            margin-left: 0px;
            text-align: left;
            /* margin-top: 5px; */
            margin-bottom: 2px;
            cursor: pointer;
            text-align: left;
            display: block;
            border-radius: 0 5px 5px 0px;
            text-decoration: none;
            background-color: #000;//var(--bb-bg-color-quaternary);
            
            &:hover{
                text-decoration: none;
                background-color: rgba(0, 0 , 0, 0.5);   
                color: rgba(255,255,255,0.8);             
            }
        }
    }
}

/* End selection sub menu */

.accordion-arrow{
    font-size: 14px !important;
}

.bb-agreement{
    max-width: 120px !important;
    height: auto !important;
    white-space: normal !important;
}

.bb-bg_blue{
    transition: 2s all;
}

.bb-white-space-nowrap{
    white-space: nowrap;
}
.commercials-page{
    height: calc(100vh - 58px); //58px is breadcumb size
}


.maxWidth-300{
    max-width: 300px !important;
} 
.maxWidth-400, .mx-400{
    max-width: 400px !important;
} 

.maxWidth-186{
    max-width: 186px !important;
}

.w-137{
    width: 137px;
}

.w-132{
    width: 132px;
}

.ww-100{
    width: 115px;
}

.tc-loader{
    position: absolute;
    top: 0;
    z-index: 123;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    height: 100%;
    svg{
        top: 150px;
        position: sticky;
    }
}