$bb-theme-color: var(--bb-theme-color);
$bb-secondary-theme-color: var(--bb-theme-color-secondary);

$bb-bg-color: var(--bb-bg-color);
$bb-bg-secondary-color: var(--bb-bg-color-secondary);
$bb-bg-tertiary-color: var(--bb-bg-color-tertiary);
$bb-bg-tertiary-color-20: var(--bb-bg-color-tertiary-20);
$bb-bg-tertiary-color-30: var(--bb-bg-color-tertiary-30);
$bb-bg-quaternary-color: var(--bb-bg-color-quaternary);
$bb-bg-quaternary-color-50: var(--bb-bg-color-quaternary-50);
$bb-disabled-color: var(--bb-color-disabled);

$bb-secondary-color: var(--bb-color-secondary);
$bb-text-primary-color: var(--bb-text-color); 
$bb-text-color-white: var(--bb-text-color-white);
$bb-text-color-black: var(--bb-text-color-black);
$bb-text-color-gray: var(--bb-text-color-gray);
$bb-text-color-grey-10: var(--bb-text-color-grey-10);


$bb-blue-color: var(--bb-blue-color);//#08b9db;
$bb-blue-secondary-color: var(--bb-blue-color-secondary);
$bb-blue-color-rgb: var(--bb-blue-color-rgb);

$bb-warning-color: var(--bb-color-warning);
$bb-error-color: var(--bb-color-error);
$bb-color-gray: var(--bb-color-gray);
$bb-color-white: var(--bb-color-white);
$bb-color-black: var(--bb-color-black);
$bb-color-green: var(--bb-color-green);
$bb-color-semi-muted: var(--bb-color-semi-muted);
$bb-color-white-secondary: var(--bb-color-white-secondary);

$bb-purple: var(--bb-purple);

$bb-green-50: var(--bb-green-50);
$bb-green-100: var(--bb-green-100);
$bb-green-200: var(--bb-green-200);
$bb-green-300: var(--bb-green-300);
$bb-green-400: var(--bb-green-400);
$bb-green-500: var(--bb-green-500);
$bb-green-600: var(--bb-green-600);
$bb-green-700: var(--bb-green-700);
$bb-green-800: var(--bb-green-800);
$bb-green-900: var(--bb-green-900);
$bb-green: var(--bb-green);

$bb-blue-50: var(--bb-blue-50);
$bb-blue-100: var(--bb-blue-100);
$bb-blue-200: var(--bb-blue-200);
$bb-blue-300: var(--bb-blue-300);
$bb-blue-400: var(--bb-blue-400);
$bb-blue-500: var(--bb-blue-500);
$bb-blue-600: var(--bb-blue-600);
$bb-blue-700: var(--bb-blue-700);
$bb-blue-800: var(--bb-blue-800);
$bb-blue-900: var(--bb-blue-900);

$bb-budster-background-color: var(--bb-budster-background-color);

// $bb-theme-color-rbg: 236, 21, 85;
* {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased ;
}



@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/CenturyGothic.ttf') format('truetype');
}

$bb-primary-font: "CenturyGothic";
$bb-secondary-font: "sans-serif";

.bb-color_primary{
    color: $bb-theme-color;
}

.fill{
  &-green > * { fill: $bb-green !important;  }
  &-gray * { fill: #CCCC !important;  }
}
.bb-bg {
  &_bg-color{background-color: $bb-bg-color !important; }
  &_quaternary{ 
    background-color: $bb-bg-quaternary-color; 
    &-50{ background-color: $bb-bg-quaternary-color-50; }
  }
  &_tertiary{
    background-color: $bb-bg-tertiary-color !important;
    &-20{ background-color: $bb-bg-tertiary-color-20 !important; }
  }
  &_decarbonise{ background-color: #6D9E3B80 !important; }
  &_purple{ background-color: $bb-purple !important; }
  &_green{
    background-color: $bb-green !important; 
    &-50{ background-color: $bb-green-50 !important; }
    &-100{ background-color: $bb-green-100 !important; }
    &-200{ background-color: $bb-green-200 !important; }
    &-300{ background-color: $bb-green-300 !important; }
    &-400{ background-color: $bb-green-400 !important; }
    &-500{ background-color: $bb-green-500 !important; }
    &-600{ background-color: $bb-green-600 !important; }
    &-700{ background-color: $bb-green-700 !important; }
    &-800{ background-color: $bb-green-800 !important; }
    &-900{ background-color: $bb-green-900 !important; }
  }
  &_blue{ 
    &-50{ background-color: $bb-blue-50 !important; }
    &-100{ background-color: $bb-blue-100 !important; }
    &-200{ background-color: $bb-blue-200 !important; }
    &-300{ background-color: $bb-blue-300 !important; }
    &-400{ background-color: $bb-blue-400 !important; }
    &-500{ background-color: $bb-blue-500 !important; }
    &-600{ background-color: $bb-blue-600 !important; }
    &-700{ background-color: $bb-blue-700 !important; }
    &-800{ background-color: $bb-blue-800 !important; }
    &-900{ background-color: $bb-blue-900 !important; }
  }
  &_white-secondary{ background: $bb-color-white-secondary !important; }
}
.bb-border{
  &-amplified{ border: 1px solid #A01A7D !important;}
  &-amplified-2{ border: 1px solid #F9D7F0 !important;}
  &-green{ 
    &-50{ border: 1px solid $bb-green-50 !important; }
    &-100{ border: 1px solid $bb-green-100 !important; }
    &-200{ border: 1px solid $bb-green-200 !important; }
    &-300{ border: 1px solid $bb-green-300 !important; }
    &-400{ border: 1px solid $bb-green-400 !important; }
    &-500{ border: 1px solid $bb-green-500 !important; }
    &-600{ border: 1px solid $bb-green-600 !important; }
    &-700{ border: 1px solid $bb-green-700 !important; }
    &-800{ border: 1px solid $bb-green-800 !important; }
    &-900{ border: 1px solid $bb-green-900 !important; }
  }
  &-blue{ 
    &-50{ border: 1px solid $bb-blue-50 !important; }
    &-100{ border: 1px solid $bb-blue-100 !important; }
    &-200{ border: 1px solid $bb-blue-200 !important; }
    &-300{ border: 1px solid $bb-blue-300 !important; }
    &-400{ border: 1px solid $bb-blue-400 !important; }
    &-500{ border: 1px solid $bb-blue-500 !important; }
    &-600{ border: 1px solid $bb-blue-600 !important; }
    &-700{ border: 1px solid $bb-blue-700 !important; }
    &-800{ border: 1px solid $bb-blue-800 !important; }
    &-900{ border: 1px solid $bb-blue-900 !important; }
  }
  &-white-secondary{ border: 1px solid $bb-color-white-secondary !important; }
}
.bb-bg_primary{
  background: $bb-theme-color;
  color: var(--bb-text-color-vendor);
}

.bb-color_{
  &bg-color{ color: $bb-bg-color !important; }
  &bg-color-tertiary{
    color: $bb-bg-tertiary-color !important;
    &-20{ color: $bb-bg-tertiary-color-20 !important; }
  }
  &white-secondary{ color: $bb-color-white-secondary; }
  &gray_imp{ color: var(--bb-color-gray) !important; }
  &secondary{
    color: $bb-secondary-color;
  }
  &secondary:hover{
    color: $bb-secondary-color !important;
  }
  &quaternary{ 
    color: $bb-bg-quaternary-color; 
    &-50{ color: $bb-bg-quaternary-color-50; }
  }
  &semi-muted{ color: $bb-color-semi-muted; }
  &error{ color: $bb-error-color !important; }
  &green{ 
    color: $bb-green !important;
    &-50{ color: $bb-green-50 !important; }
    &-100{ color: $bb-green-100 !important; }
    &-200{ color: $bb-green-200 !important; }
    &-300{ color: $bb-green-300 !important; }
    &-400{ color: $bb-green-400 !important; }
    &-500{ color: $bb-green-500 !important; }
    &-600{ color: $bb-green-600 !important; }
    &-700{ color: $bb-green-700 !important; }
    &-800{ color: $bb-green-800 !important; }
    &-900{ color: $bb-green-900 !important; }
  }
  &blue{ 
    &-50{ color: $bb-blue-50 !important; }
    &-100{ color: $bb-blue-100 !important; }
    &-200{ color: $bb-blue-200 !important; }
    &-300{ color: $bb-blue-300 !important; }
    &-400{ color: $bb-blue-400 !important; }
    &-500{ color: $bb-blue-500 !important; }
    &-600{ color: $bb-blue-600 !important; }
    &-700{ color: $bb-blue-700 !important; }
    &-800{ color: $bb-blue-800 !important; }
    &-900{ color: $bb-blue-900 !important; }
  }
}
.bb-margin_{
  &5{ margin: 5px;}
  &10{ margin: 10px;}
}
.text-green{
  color: $bb-green !important;
}
.bb-bg_secondary{
  background: $bb-secondary-theme-color;
  p{
    color: $bb-text-color-white;
  }
}
.bb-color-grey{
  color: $bb-text-color-grey-10 !important;
}
.bb-color_blue{
  color: $bb-blue-color;
}
.bb-bg_blue{
  background: var(--bb-blue-700) !important;
  color: var(--bb-text-color-white) !important;
}
.bb-color_secondary-blue{
  color: $bb-blue-secondary-color;
}
.bb-bg_secondary-blue{
  background: $bb-blue-secondary-color !important;
}

.bb-bg_whitesmoke{
  background: $bb-bg-secondary-color;
}

.bb-bg_budster{
  background-color: $bb-budster-background-color  !important;
}

.bb-bg_disabled{
  background: $bb-disabled-color;
}
.bb-color_disabled{
  color: $bb-disabled-color;
  &_imp{ color: $bb-disabled-color !important; }
}
.bb-input-bg_disabled{
  background: lightgray !important;
}

.bb-bg_warning{
  background: $bb-warning-color !important;
}

.bb-text_warning{
  color: orange !important;
}

.bb-text_small{
    font-size: small;
}

.bb-fill_vendor_color{
  fill: var(--bb-text-color-vendor);
}

.bb-text_bold{
  font-weight: bold;
}

.text-black{
  color: $bb-text-color-black;
  &_imp{ color: $bb-text-color-black !important; }
}
.text-underline{
  text-decoration: underline;
  &.no_hover{
    &:hover{
      color: var(--bb-text-color-white) !important;
    }
  }
}

.bb-border-gray{
  border: 1px solid gray;
  border-radius: 10px;
}

.bb-border-yellow{
  border: 1px solid $bb-secondary-color;
}
.bb-rounded{
  border-radius: 5px;
}
.bb-font{
  &_7px{ font-size: 7px; }
  &_10px{ font-size: 10px; }
  &_11px{ font-size: 11px; }
  &_17px{ font-size: 17px; }
  &_12px{ font-size: 12px; }
  &_13px { font-size: 13px;}
  &_13px_imp { font-size: 13px !important;}
  &_14px { font-size: 14px;}
  &_16px { font-size: 16px;}
  &_14px_imp{ font-size: 14px !important; }
  &_20px{ font-size: 20px; }
  &_24px{ font-size: 24px; }
  &_28px{ font-size: 28px; }
}
.bb-color_oops{
  color: var(--bb-buddy-oops-1) !important;
}
.bb-bg_oops{
  background-color: var(--bb-buddy-oops-3);
  color: var(--bb-buddy-oops-1) !important;
}
.bb-bg_nay{
  background-color: var(--bb-buddy-nay-3);
  color: var(--bb-buddy-nay-1) !important;
}

.error{
    border: 1.5px solid $bb-error-color;
}
.position-relative{
  position: relative;
}
.cursor{
  &-pointer{ cursor: pointer !important; }
  &-default{ cursor: default !important; }
}

.bb-translateX_minus100{
    @include translateX(-100%, true);
}

.bb-translateX_0{
    @include translateX(0%, true);
}

.bb-transition-5ms{
    transition: all 0.5s;
}
.cert-img {
  width: 30%;
  min-width: 150px;
  height: auto;
  max-width: 360px;
  position: absolute;
  right: 0;
}
.acc-img {
  width: 57px;
  height: 57px;
  object-fit: cover;

  &.payment {
      width: 60px;
      height: 36px;
      border-radius: 5px;
  }
}
.height_33px{ height: 33px !important; }
.bb-search-input{
    width: 200px !important;
    border: none;
    font-size: 16px;
    background-image: url('~/public/images/search-icon.png');
    background-size: 16px;
    background-repeat: no-repeat;
    padding-left: 28px !important;
    background-position: 5px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    border-radius: 5px;
    outline: none;
    height: 100%;
    &:focus{
      width: 100%;
    }
  }
  .bb-search-input-light{
    margin: 0 !important;
    padding: 4px 12px 4px 27px !important;
    border: 1px solid var(--bb-primary-background-0);
    box-sizing: border-box;
    box-shadow: 2px 2px 8px RGB(32 33 36 / 3%);
    border-radius: 5px;
    font-family: "Ubuntu";
    font-size: 13px;
    line-height: 21px;
    height: 29px;
    width: 150px !important;
    &::placeholder{
      color: var(--bb-primary-text-3)
    }
    &:hover{
      border: 1px solid var(--bb-primary-text-4);
      box-shadow: 2px 2px 8px rgba(32, 33, 36, 0.03);
    }
    &:disabled{
      background: var(--bb-primary-background-05);
    }
    &:focus{
      border: 1px solid var(--bb-secondary-functionality-1);
      box-shadow: 2px 2px 8px rgba(20, 161, 255, 0.15);
      &::placeholder{
        color: transparent
      }
    }
  }

/* 
  .bb-status-filter {   
    background: $bb-theme-color ;
    border-radius: 5px ;
    border: none ;
    outline: none ;
    width: auto ;
    max-width: 170px ;
    color: var(--bb-text-color-vendor);
    text-align-last: center;
    &-text{
        text-align: center;  
        align-self: center;
    }
    &:focus{
      background: $bb-color-white !important;
    }
    &.warning{
      background: var(--bb-color-warning);
    }
    &.success{
      background: #28a745;
    }
  } */
  
  .bb-table-status-filter {   
    background: $bb-secondary-theme-color;
    border-radius: 5px ;
    border: none ;
    outline: none ;
    width: auto ;
    max-width: 170px ;
    color: $bb-text-color-white ;
    text-align-last: center;
    font-size: 13px;
    padding: 3px;
    &-text{
        text-align: center;  
        align-self: center;
    }
  }

  .bb-media-sortby{
    width: 160px ;
  }

  .required {
    display: block;
    padding: 2px;
    font-size: small;
    color: $bb-error-color;
    font-weight: bold;
  }
  .required-nt {
    display: none;    
  }
/* 
  .required{ 
    background: #e61516 !important;
    padding: 2px 5px !important;
    border-radius: 5px !important;
    position: relative;
    display: inline-block !important;
    margin-top: 5px;
    box-shadow: 2px 2px 10px 1px #ccc;
    font-size: small;
  }
  .required:before{ 
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #e61516;
    position: absolute;
    top: -5px;
  }
   */

  .bb-halfpage-logo{

    .img-top{
      // display: none;
      opacity: 0;
      position: absolute;
      top: 0;
      // left: 0;
      z-index: 99;
      transition: all 1s;
    }

    &:hover .img-top{
      // display: inline;
      opacity: 1;
    }
  }

  .bb-user-avatar{
    cursor: pointer;
    object-fit: cover;
    
    &:hover{
      color: var(--bb-text-color-vendor) !important;
      background: $bb-theme-color !important;
    }
  }

.react-tooltip{
  background-color: $bb-budster-background-color  !important;
  opacity: 1 !important;
  z-index: 99999;
  &.fake-tooltip{
    position: absolute;
    margin: 0 auto;
    left: 71px;
    max-width: 218px;
    text-align: center;
    bottom: -126px;
    .react-tooltip-arrow{
      left: 108px;
      top: -4px;
      transform: rotate(45deg);
      width: 8px;
      height: 8px;
      position: absolute;
      background-color: $bb-budster-background-color  !important;
      opacity: 1 !important;
      z-index: 99999;
    }
  }
}
/* React overlay spinner */
.react-overlay-loader-spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -30px;
    margin-left: -20px;
    border-radius: 50%;
    border: 5px solid $bb-text-color-white;
    border-top-color: $bb-theme-color;
    animation: react-overlay-loader-spinner 0.8s linear infinite;
  }
  
  @keyframes react-overlay-loader-spinner {
    to {
      transform: rotate(360deg);
    }
  }


  /* CRUD UIs */

  .bb-all-contents{
    width: 100%;
  }


  .bb-small-pic{
    width: 24px;
    height: 24px;    
  }

  .bm-logo-circle-coming-soon{
    width: 300px;
    height: auto;
  }

  .fa-1dot5x{
    font-size:20px;
  }

  .fa-2dot7x{
    font-size: 2.7em;
  }

  .mw-200px{
    max-width: 200px;
  }
  .mw-610px{
    max-width: 610px;
  }
  
  .mb-{
    &12{ margin-bottom: 12px !important; }
    &32{ margin-bottom: 32px !important; }
    &40{ margin-bottom: 40px !important; }
  }
  .mt-{
    &32{ margin-top: 32px !important; }
    &40{ margin-top: 40px !important; }
  }
  .disabled{
    pointer-events: none;
  }

  .bb-circle-with-text {
    background: $bb-theme-color;
    border-radius: 50%;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    text-align: center;
    font-weight: bold;
    color: var(--bb-text-color-vendor);
    span{
      // vertical-align: -webkit-baseline-middle;  
      align-self: center;    
    }
    &._preview{
      height: auto;
      width: 20px;
      min-width: 20px;
    }
}

.bb-logo-created-by{
  width: 80px;
  height: 80px;
}

.channel-selector{
  img{
    max-width: 200px;
    height: 200px;
  }
}

.bb-ad-title{
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
  align-self: center;
  margin-bottom: 0;
  max-width: 240px;
}

/* BB-Loader */

.bb-loader {
  
  width: 75px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -7px;
  position: absolute;
  
  &__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    background: $bb-theme-color;
    color: var(--bb-text-color-vendor);
    transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0,0,0,.2);
    
    @for $i from 1 through 5 { 
       &:nth-child(#{$i}) {
         left: ($i - 1) * 15px;
         transform: scale(1,$i*.2);
         animation: barUp#{$i} 4s infinite;
        }
    }
    
  }

  .green {
    background: var(--bb-green);
  }
  
  &__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    animation: ball 4s infinite;
  }
}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px)
  }
  17% {
    transform: translate(23px, -24px)
  }
  20% {
    transform: translate(30px, -20px)
  }
  27% {
    transform: translate(38px, -34px)
  }
  30% {
    transform: translate(45px, -30px)
  }
  37% {
    transform: translate(53px, -44px)
  }
  40% {
    transform: translate(60px, -40px)
  }
  50% {
    transform: translate(60px, 0)
  }
  57% {
    transform: translate(53px, -14px)
  }
  60% {
    transform: translate(45px, -10px)
  }
  67% {
    transform: translate(37px, -24px)
  }
  70% {
    transform: translate(30px, -20px)
  }
  77% {
    transform: translate(22px, -34px)
  }
  80% {
    transform: translate(15px, -30px)
  }
  87% {
    transform: translate(7px, -44px)
  }
  90% {
    transform: translate(0, -40px)
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 { 
  0% {
    transform: scale(1, .2);
  }
  40%{
    transform: scale(1, .2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1,.2);
  }
}
@keyframes barUp2 { 
  0% {
    transform: scale(1, .4);
  }
  40% {
    transform: scale(1, .4);
  }
  50% {
    transform: scale(1, .8);
  }
  90% {
    transform: scale(1, .8);
  }
  100% {
    transform: scale(1, .4);
  }
}
@keyframes barUp3 { 
  0% {
    transform: scale(1, .6);
  }
  100% {
    transform: scale(1, .6);
  }
}
@keyframes barUp4 { 
  0% {
    transform: scale(1, .8);
  }
  40% {
    transform: scale(1, .8);
  }
  50% {
    transform: scale(1, .4);
  }
  90% {
    transform: scale(1, .4);
  }
  100% {
    transform: scale(1, .8);
  }
}
@keyframes barUp5 { 
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, .2);
  }
  90% {
    transform: scale(1, .2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.bb-badge-primary{
  background: $bb-secondary-color;
  color: $bb-text-color-white;
  font-size: 12px;
  font-weight: normal;
  &.success{
    background: #28a745;
  }
  &.danger{
    background: var(--bb-theme-color);
    color: var(--bb-text-color-vendor);
  }
  &.init{
    background: lightgray;
    color: $bb-text-color-black;
  }
}

.bb-creative-info-box{
  border: 1px solid $bb-disabled-color;
  border-radius: 5px;
  padding: 20px;
  background: $bb-bg-quaternary-color;
  position: relative;

  &-archived{
    border: 2px dashed $bb-secondary-color;
    border-radius: 5px;
    padding: 20px;
    background: $bb-bg-quaternary-color;
  }
}

.bb-creative-info-image-box{
  width: 100%;
  overflow: hidden;
  height: 200px;
  display: flex;
  align-self: center;
  background: #8A8A8A;
  justify-content: center;
  max-width: 200px;
  &.swipe-icon{
    width: auto !important;
    background: #484D5C
  }
  img{
    width: 100%;
    height: auto;
    align-self: center;
    max-width: 200px;
    &.swipe-icon{
      max-height: 200px !important;
      width: auto !important;
      border: 1px solid white;            
  }
  }
}

.creative-image-container .bb-creative-info-image-box{ background: $bb-bg-tertiary-color-20; }
.fix-button-overlap{
  padding-right: 200px !important;
}

.text-transform-none{
  text-transform: none !important
}
.no-opacity{
  opacity: 1 !important;
}
.opacity-none{ opacity: 0 !important;}
.opacity-faded{ opacity: 0.4 !important;}
.opacity-disabled{ opacity: 0.7 !important;}
.hover-animate:hover{
  -webkit-animation-name: swing;
  animation-name: swing;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bb-creative-preview-name{
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;  
}

.inactive{
  display: none;
}

.bb-wmc{
  width: max-content;
}


/* Light Theme */
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu-Regular.ttf') format('truetype');
}

$bb-primary-font-v2: "Ubuntu";
.root-light{
  background: var(--bb-primary-background-1) !important;
  color: var(--bb-primary-text-1) !important;
  #bb-container-main{
    font-family: $bb-primary-font-v2 !important;
    background: var(--bb-primary-background-1) !important;
    color: var(--bb-primary-text-1) !important;
  }
  .underlined_bb{
    /* max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    max-width: fit-content;
    color: var(--bb-primary-text-1) !important;
    font-family: $bb-primary-font-v2 !important;
   
  }
  .bb-breadcrumbs-title{
      font-size: 17px;
      line-height: 20px;
      color: var(--bb-primary-text-2);
  }
  .bb-breadcrumbs-line {
      border: 0.5px solid var(--bb-primary-background-0);
  }
}

.bb-header-txt{
    font-size: 21px;
    line-height: 34px;
    font-family: $bb-primary-font-v2;
}
.bb-sub-header-txt{
  font-size: 17px;
  line-height: 28px;
  font-family: $bb-primary-font-v2;
}
.bb-body-txt{
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 1%;
  .root-light{
    font-family: $bb-primary-font-v2;
  }
}
.bb-overline-txt{
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 1%;
  font-family: $bb-primary-font-v2;
}
.bb-value-txt{
  font-weight: bold;
  font-size: 27px;
  line-height: 34px;
  font-family: $bb-primary-font-v2;
}
.bb-grey-box-shadow{
  box-shadow: 2px 2px 8px var(--bb-primary-text-1);
  opacity: 0.03;
}
.bb-blue-box-shadow{
  box-shadow: 2px 2px 8px var(--bb-secondary-functionality-1);
  opacity: 0.15;
}
.bb-pink-box-shadow{
  box-shadow: 2px 2px 8px var( --bb-primary-pink-1);
  opacity: 0.15;
}
.bb-primary-border{
  border: 1px solid var(--bb-primary-text-4);
  box-sizing: border-box;
  border-radius: 5px;
}
.bb-input-color-border{
  border: 1px solid #ced4da !important;
}
.bb-secondary-border{
  border: 1px solid var(--bb-primary-background-0);
  box-sizing: border-box;
  border-radius: 5px;
}

.bb-tooltip-icon-light{
  background-color: white;
  padding: 0px !important;
  width: 16px;
  height: 16px;
  color: var(--bb-primary-blue-2) !important;
  font-size: 8px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bb-primary-blue-2);
}
.bb-icon-light{
  color: var(--bb-primary-blue-1) !important;
}
.bb-tooltip-light{
  padding: 8px 12px !important;
  max-width: 195px !important;
  background: var(--bb-primary-blue-1) !important;
  border-radius: 5px !important;
  white-space: normal;
}
.bb-tooltip-dark{
  padding: 8px 12px !important;
  max-width: 195px !important;
  border-radius: 5px !important;
}
.bb-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.bb-form-title_color{
  color: var(--bb-bg-color-gray);
}
.bb-form-input_color, .deal-status-label{
  color: var(--bb-color-gray);
}
.bb-form-line-divide, .bb-bg-tertiary-20{
  background: var(--bb-bg-color-tertiary-20);
}
.bb-form-line-divide_grey{
  background: #CACBD333 !important;
}
.budster-reminder{
  background-color: #CACBD333;
  border-radius: 8px;
}
.switch-page-bar{
  color: #858585;
  border-bottom: 1px solid $bb-bg-tertiary-color-20;
  > div{ 
    font-size: 16px;
    padding: 5px 30px;
    cursor: pointer;
    transition: color 0.3s linear;
  }
  .selected-page{
    cursor: default;
    font-weight: bold;
    color: white;
    border-bottom: 2px solid $bb-blue-600;
  }
}
.overflow-visible{ overflow: visible !important; }
.overflow-unset{ overflow: unset !important; }
.d-contents{ display: contents; }

.bb-deal-box{
  background: var(--bb-bg-color-quaternary);
  max-width: 596px;
  border-radius: 5px;
  border: 1px solid var(--bb-bg-color-gray);
  line-height: 21px;

  &.creative-box{ 
    // padding: 24px 32px;
  }
  &.kritikos-box{
    min-width: 210px;
  }
  .bb-deal-info-image-box {
    width: 150px;
    overflow: hidden;
    height: 150px;
    display: flex;
    align-self: center;
    justify-content: center;
    max-width: 150px;
    min-width: 150px;

    img {
      width: 100%;
      align-self: center;
    }
  }
  &.bb-offset-box .bb-deal-info-image-box{
    width: 80px;
    height: 80px;
    max-width: 80px;
    min-width: 80px;
  }
  &-content{
      max-width: 564px;
  }
  &-name{
      max-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
  }
}

.accordion-box{
  background: var(--bb-bg-color-quaternary-50);
  padding: 0px 24px;
  &.token{
    max-width: 766px;
  }
  &-header{
      padding: 24px 0;
  }
  &-content{
      background: var(--bb-bg-color-tertiary);
      padding: 18px 24px;
      width: 300px;
      border-radius: 8px;
      margin-bottom: 24px;
      box-shadow: 2px 2px 5px 0px rgba(10, 13, 26, 0.25);
  }
  .accordion-arrow{
      transition: 1s all;
      &.rotate{
          transform: rotate(90deg);      
      }
  }
  .accordion-content{
      max-height: 0;
      transition: all 0.5s ease-in-out;
      overflow: hidden;
      &.open {
          max-height: 100% !important;
      }
  }
}
.bb-scrollable {
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 7px; /* Total width including `border-width` of scrollbar thumb */
    height: 7px;
    box-shadow: inset 0 0 100px #2E3547;
    // border-radius: 10px;
    margin-right: 20px;
    
    &-thumb{
        height: 1em;
        border: 0.1em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: rgba(202, 203, 211, 0.20);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track{
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
        //border-radius: 10px;
        padding: 10px;
    }
}
}
.bb-tcs-container {
  height: 100%;
  max-height: 180px;
  overflow: auto;
  border-radius: 5px;
  width: 780px;
  font-size: 14px;

  &.xlarge{
    max-height: 460px;
  }
  &.large{
    max-height: 350px;
  }

  &.offset-cert{
    width: 552px;
  }
  &::-webkit-scrollbar {
      width: 10px;
      /* Total width including `border-width` of scrollbar thumb */
      height: 0;
      box-shadow: inset 0 0 50px var(--bb-bg-color-tertiary);
      border-radius: 5px;

      &-thumb {
          height: 20px;
          border: 2px solid rgba(0, 0, 0, 0);
          /* Transparent border together with `background-clip: padding-box` does the trick */
          background-clip: padding-box;
          -webkit-border-radius: 0.5;
          background-color: var(--bb-theme-color);
          border-radius: 5px;
      }

      &-track {
          border-radius: 5px;
          padding: 5px;
      }
  }

  .react-pdf__Page {
      margin-top: 10px;

  }

  .react-pdf__Page__textContent {
      border: 1px solid darkgrey;
      box-shadow: 5px 5px 5px 1px var(--bb-color-gray);
  }

  .react-pdf__Page__annotations.annotationLayer {
      padding: 20px;
  }
}

.bb-dashboard{
  &-content, &-hero{
      padding: 40px 22px;
  }
  &-hero{
      width: 100%;
      background: url("~/public/images/programs-hero.jpg"), lightgray -2px -222.906px / 100.358% 239.316% no-repeat;
      background-size: cover;
      h2{ font-size: 35px; }
      &.performance{
        background-image: url("~/public/images/programs-hero.jpg");
        background-position: center;
      }
  }
  &-button{
      padding: 5px 30px !important;
      margin-top: 16px;
  }
}
.feedback-status-badge, .performance-status-badge{
  border-radius: 50px;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 12px;
  align-self: center;
  text-transform: uppercase;
}
.enterprise-badge{
  border-radius: 50px;
  font-size: 12px;
  padding: 4px 8px;
  line-height: 12px;
  align-self: center;
  text-transform: uppercase;
  width: fit-content;
  justify-self: center;
  &, &.badge-draft{ background: $bb-bg-color; }
  &.badge-pending-bigads-approval{ background: rgba(8, 185, 219, 0.64); }
  &.badge-pending-client-approval{ background: rgba(242, 153, 74, 0.64); }
  &.badge-approved{ background: rgba(40, 167, 69, 0.64); }
  &.badge-terminated{ background: rgba(220, 53, 69, 0.64); }
}
.feedback-status-badge{
  &.badge-pending-revision{ background: $bb-bg-color; }
  &.badge-feedback-received{ background: #F2994AA3; }
  &.badge-in-production{ background: #08B9DBA3; }
  &.badge-approved{ background: #28A745A3; }
  &.badge-disregarded{ background: #DC3545A3; }
  &.badge-former-version{ background: #858585A3; }
  &.badge-assigned{ background: #28A7455C; }
}
.performance-status-badge{
  &.badge-pending{ background: #1C2135; }
  &.badge-on-track{ background: #28A7455C; }
  &.badge-failing{ background: #DC35455C; }
  &.badge-fulfilled{ background: #28A745A3; }
  &.badge-failed{ background: #DC3545A3; }
}
.w{
  &-350{ width: 350px;}
}
.mw{
  &-unset { max-width: unset !important; }
  &-fit-content { max-width: fit-content !important; }
}
.min-h{
  &-250 { min-height: 250px !important; }
}
.min-w{
  &-unset { min-width: unset !important; }
  &-fit-content{ min-width: fit-content; }
}
.hover-main{
  transition: 0.5s;
  &:hover{
    color: white;
    text-decoration: none;
    border-bottom: 1px solid $bb-text-primary-color;
  }
}
.border-none_imp{ border: none !important;}
.half-opacity{ opacity: 0.5; }

.space-normal{ white-space: normal; }
.creatives-table{
  min-width: unset;
}

@media (min-width: 576px){
  .creatives-table{
    min-width: fit-content;
  }
  .bb-dashboard-hero{
    padding: 96px;
    height: 320px;
  }
  .offset-accordion-box{
    width: 548px;
  }
  .pricing-offset{
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
.fit{
  &-contain{ object-fit: contain; }
  &-cover{ object-fit: cover; }
}