
  .bb-error-page{ 
    min-height: 100vh;
    h2, button, img{
        z-index: 2;
    }
    img{
      width: 400px;
      filter: invert(0.1);
    }

    @keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    @-webkit-keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    @-moz-keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    @-ms-keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    
    .stars, .twinkling {
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      width:100%;
      height:100%;
      display:block;
    }
    
    .stars {
      background:#000 url('~/public/images/404/stars.png') repeat top center;
      z-index:0;
    }
    
    .twinkling{
      background:transparent url('~/public/images/404/twinkling.png') repeat top center;
      z-index:1;
    
      -moz-animation:move-twink-back 200s linear infinite;
      -ms-animation:move-twink-back 200s linear infinite;
      -o-animation:move-twink-back 200s linear infinite;
      -webkit-animation:move-twink-back 200s linear infinite;
      animation:move-twink-back 200s linear infinite;
    }
    
  }