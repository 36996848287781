:root, .rs-theme-light {
  --rs-text-secondary: #8e8e93;
  --rs-border-primary: #e5e5ea;
  --rs-bg-overlay: #fff;
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-input-bg: #fff;
  --rs-listbox-option-hover-bg: #deebff;
  --rs-listbox-option-selected-bg: #2684ff;
  --rs-listbox-option-selected-text: var(--bb-text-color-white);
  --rs-listbox-option-disabled-text: #c5c6c7;
}
.bb-status-filter{
  input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
.rs-picker{
  &-select-menu-items {
    max-height: 200px !important;
    overflow-y: auto;

    .rs-picker-menu-group:not(:first-child){
      border-top: 1px solid var(--rs-border-primary);
      margin-top: 6px;
      padding-top: 6px
    }

    .rs-picker-menu-group-title{
      color: var(--bb-primary-text-3);
      cursor: default;
      padding: 8px 32px 8px 12px;
      position: relative;
      -webkit-transition: color .3s linear, background-color .3s linear;
      transition: color .3s linear, background-color .3s linear;
      &:hover{
        background-color: #f2faff;
      }
      .rs-picker-menu-group-caret {
        display: inline-block;
        margin-left: 2px;
        padding: 3px;
        position: absolute;
        right: 12px;
        top: 8px;
        -webkit-transition: -webkit-transform .3s linear;
        transition: -webkit-transform .3s linear;
        transition: transform .3s linear;
        transition: transform .3s linear, -webkit-transform .3s linear
      }      
    }

    .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg)
  }
    
    &::-webkit-scrollbar{
      width: 1.25em;
      height: 0;
      box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
      &-thumb {
        height: 3em;
        border: 0.3em solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: var(--bb-bg-color-tertiary-20);
        border-radius: 10px;
      }
      &-track { border-radius: 10px; padding: 10px; }
    }
  }
  &-input {
    position: relative;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    -webkit-transition: border-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s;
    background-color: var(--rs-input-bg);
    .rs-picker-toggle {
      border: none !important;
      background: transparent !important;
      height: 34px;
      cursor: text;
    }
    &:not(.rs-picker-disabled) .rs-picker-toggle { position: absolute !important; }
  }
  &-cleanable .rs-picker-tag-wrapper { margin-right: 44px; }
  &-search-input {
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    background: none;
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    padding-right: 0;
  }
  &-focused .rs-picker-search-input { z-index: 6; }
  &-block { display: block; }
  &-disabled { opacity: 0.3; }
  &-toggle .rs-picker-toggle-placeholder { color: var(--rs-text-secondary);}
  &-has-value{
    .rs-btn .rs-picker-toggle-value, .rs-picker-toggle .rs-picker-toggle-value{ margin-right: 8px;}
  }
  &-none {
    padding: 6px 12px 12px;
    color: var(--rs-text-secondary);
    cursor: default;
  }
  &-toggle {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    border: none;
    border: var(--rs-btn-default-border, none);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: none;
    border-radius: 6px;
    //font-size: 14px;
    //line-height: 20px;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    &-caret {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 2px;
      position: absolute;
      top: 8px;
      right: 12px;
      height: 20px;
      font-size: 12px;
      color: var(--rs-text-secondary);
    }
    &-read-only {
      opacity: 0;
      pointer-events: none;
    }
    &-clean {
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-left: 2px;
      position: absolute;
      top: 8px;
      right: 30px;
      height: 20px;
      font-size: 12px;
      font-weight: bold;
      background: inherit;
      color: var(--rs-text-secondary);
      -webkit-transition: 0.2s color linear;
      transition: 0.2s color linear;
      cursor: pointer;
      &:hover { color: var(--bb-color-error); }
    }
  }
  &-toggle-textbox {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 1px solid #0000;
    padding-left: 10px;
    padding-right: 32px;
    color: var(--bb-text-color-black);
  }
  &-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    cursor: pointer;
    color: var(--bb-text-color-black);
    border: 1px solid var(--rs-border-primary);
  }
  &-disabled .rs-picker-toggle { cursor: not-allowed; }
  &-menu {
    position: absolute;
    text-align: left;
    z-index: 1000;
    border-radius: 6px;
    background-color: var(--rs-bg-overlay);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
    overflow: hidden;
    -webkit-transition: none;
    transition: none;
  }
  &-select-menu-item {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: var(--bb-text-color-black);
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    font-size: 14px;
    &:hover, &:focus, &.rs-picker-select-menu-item-focus {
      background-color: var(--rs-listbox-option-hover-bg);
    }
    &.rs-picker-select-menu-item-active {
      background-color: var(--rs-listbox-option-selected-bg);
      color: var(--rs-listbox-option-selected-text);
    }
    &.rs-picker-select-menu-item-disabled {
      color: var(--rs-listbox-option-disabled-text);
      background: none;
      cursor: not-allowed;
    }
  }
  &:not(.rs-picker-disabled):hover, &.rs-picker-focused { border-color: #3498ff; }
  &-toggle-active, &.rs-picker-focused {
    -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
    -webkit-box-shadow: var(--rs-state-focus-shadow);
            box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
            box-shadow: var(--rs-state-focus-shadow);
  }
}
.rs-modal-open .rs-picker-menu {
  z-index: 1055;
}

.bb-input-picker {
  top: unset !important;
  left: unset !important;
}