#bb-popup-notification {
  position: fixed;
  font-family: "CenturyGothic" !important;
  left: 220px;
  
  .pop {
    position: absolute;
    .notif-badge{
        color: var(--bb-blue-500);
        -webkit-text-stroke: 2px var(--bb-bg-color-tertiary);
        position: relative !important;
        bottom: -10px;
        right: 21px;
    }
  }
}

.notification-box-container {
  width: 370px;
  height: 500px;
  border-radius: 10px;
  background-color: #fff;
  padding: 0px;
  position: fixed;
  bottom: 14px;
  left: 264px;
  z-index: 99;
  .notif-subheader{
    border-bottom: 1px solid var(--bb-color-gray);

    .filter{
      height: 23px;
      color: var(--bb-bg-color-gray);
      span{ padding: 5px 8px;}
    }
    .selected{
      border-bottom: 1px solid var(--bb-blue-600);
      color: var(--bb-blue-600);
      font-weight: bold;
    }
  }
  .notification-wrapper {
    overflow-y: auto;
    overscroll-behavior-y: none;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
    height: 0;
    box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
    border-radius: 0px;

    &-thumb {
      height: 10px;
      border: 2px solid transparent;
      background-clip: padding-box;
      -webkit-border-radius: 0.5;
      background-color: var(--bb-bg-color-tertiary-20);
      border-radius: 5px;
    }

    &-track {
      border-radius: 5px;
      padding: 5px;
    }
  }

  .chat-button {
    background-color: var(--bb-bg-color-tertiary-20);
    border-radius: 20px;
    opacity: 0 !important; 
  }
  .notif-box {
    border-bottom: 1px solid #CED4DA;
    &:hover{
      background-color: var(--bb-bg-color-tertiary-20);
      .chat-button{ opacity: 1 !important; }
      .notif-msg{ 
        color: black !important;
        text-decoration: underline;
      }
    }
    &.read-msg .notif-msg{
      color: #8E8E93;
    }
    .chat-time{
      color: var(--bb-color-semi-muted);
    }
  }
}
.notification-overlay{
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--bb-bg-color-tertiary);
  top: 0;
  opacity: 0.3;
  z-index: 99;
}
@media (max-width: 576px) {
  .notification-box-container{
    top: 50%;
    left: 50% !important;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    max-height: 85vh;
    z-index: 9999999;
  }
}