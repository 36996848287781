
:root {

  /* Primary */
  --bb-primary-navy: #1c2134;

  --bb-primary-pink-0: #cc1943;
  --bb-primary-pink-05: #e01f4c;
  --bb-primary-pink-1: #e83457;
  --bb-primary-pink-2: #f5a3b4;

  --bb-primary-blue-1: #4dadc5;
  --bb-primary-blue-2: #b2dbe6;

  --bb-primary-text-1: #202124;
  --bb-primary-text-2: #787575;
  --bb-primary-text-3: #a6a6a6;
  --bb-primary-text-4: #d9d9d9;

  --bb-primary-background-0: #e5e5e5;
  --bb-primary-background-05: #f2f2f2;
  --bb-primary-background-1: #f8f9fa;
  --bb-primary-background-2: #ffffff;

  /* Secondary */

  --bb-secondary-functionality-0: #0284cf;
  --bb-secondary-functionality-05: #0592f0;
  --bb-secondary-functionality-1: #14a1ff;
  --bb-secondary-functionality-2: #99c9ff;

  /* Data */

  --bb-data-a-1: #fec663;
  --bb-data-b-1: #e06e5e;
  --bb-data-c-3: #8cca8b;
  --bb-data-d-4: #1272a4;
  --bb-data-e-5: #01baef;


   /* notifications */

   --bb-buddy-oops-3: #FDEDC4;
   --bb-buddy-oops-1:#EDAC0D;
   
   --bb-buddy-nay-3:#F9D7DA;
   --bb-buddy-nay-1:#DD2C3B;


  }
  