
.accordion-arrow{
        display: inline-block;
        border-radius: 50%;
        vertical-align: middle;
        cursor: pointer;
        .right {
            display: inline-block;
            width: 35px;
            height: 35px;
            background: #f59c02;
            border-radius: 50%;
        }
        .right:after {
            content: '';
            display: inline-block;
            margin-top: 12px;
            margin-left: 10px;
            width: 12px;
            height: 12px;
            border-top: 0.3em solid white;
            border-right: 0.3em solid white;
            transform: rotate(45deg); 
            transition: all 0.5s ease-in-out;
        }
        .bottom:after{
            content: '';
            display: inline-block;
            margin-top: 11px;
            margin-left: 11px;
            width: 12px;
            height: 12px;
            border-top: 0.3em solid white;
            border-right: 0.3em solid white;
            transform: rotate(135deg);
            transition: all 0.5s ease-in-out;

        }
}
.accordion-content {
    max-height: 0;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    &.open {  
        max-height: 100% !important;
    }
}