
.bb-message-container{
    max-width: 500px !important;
    background: #1c2135 !important;
    color: $bb-text-color-white !important;
    border: 1px solid $bb-color-white;
    width: fit-content !important;
    min-height: fit-content !important;
    &.mw-unset{ max-width: unset !important;}
}

.bb-modal-container{
    max-width: 750px;
    // max-height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    background: $bb-bg-secondary-color;
    min-width: 300px;
    min-height: 300px;    
    width: 95%;    
    border-radius: 10px;
    color:$bb-text-color-black;
    z-index: 99;    
    transition: all 1s;
    border: none;
    
    &-mw-550{
        max-width: 550px;
    }
    &-mw-fc{
        max-width: fit-content !important;
        background: transparent !important;
    }
    &-decarbonise-info{
        max-width: 900px;
        height: 400px;
    }
    .bb-modal{
        overflow-x: hidden;
        overflow-y: auto;
        min-height: 300px;
        
        &-header, &-footer{
            z-index: 2;
            position: relative;
            background: $bb-bg-secondary-color;
        }
        &-close{
            position: fixed;
            right: 0px;
            top: 0px;
            padding: 10px;
            z-index: 2;
        }

        &-mh-400{
            max-height: 400px;
        }
        &{
            max-height: 500px;
        }

        &::-webkit-scrollbar {
            width: 10px; /* Total width including `border-width` of scrollbar thumb */
            height: 0;
            box-shadow: inset 0 0 50px var(--bb-bg-color-tertiary);
            //border-radius: 10px;       
            &-thumb{
                height: 10px;
                border: 2px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
                background-clip: padding-box;
                -webkit-border-radius: 0.5;
                background-color: $bb-theme-color;
                border-radius: 5px;
            }
        
            &-track{
                border-radius: 5px;
                padding: 5px;
            }
        }
    }
    &.bb-max-size{
        &, .bb-modal{
            height: 98vh;
            max-height: 98vh !important;
            width: 98vw;
            max-width: 98vw !important;
        }
        .bb-image-modal{
            max-width: 100%;
            max-height: 100%;
        }
    }

    /* .bb-modal > div:nth-child(1){
        z-index: 2;
        background: whitesmoke;
        position: sticky;
        top: 0;       
        padding-top: 1rem;
    } */
     .sticky-header{
        position: sticky;
        top: 0;
        background: $bb-bg-secondary-color;
        z-index: 9;

        .bb-modal-close {
            position: absolute;
        }

        h5{
            margin-bottom: 0;
        }
     }
}
.bb-modal-overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}


.bb-modal-add-user{
    max-width: 1080px;
}

.bm-logo-circle{
    width: 200px;
    position: relative;
    left: -38px;
    top: -22px;
    z-index: 1;
    height: 100%;
}

.bb-deals-sites-list{
    -webkit-columns: 180px 5;
    -moz-columns: 180px 5;
    columns: 180px 5;
    list-style-type: none;
    padding: 0;
    li{
        text-overflow: ellipsis; 
        white-space: nowrap; 
        overflow: hidden;
    }
}

.report-modal{
    min-height: 500px;
}

.deal-info-modal{
    max-height: 90vh;
}

.password-modal{
    max-width: 660px;
    overflow: auto;
}

.script-modal{
    min-height: 400px;
}

/* FOR NEXD */
.nexd-app-move-left{
    max-width: 700px;
    margin: 0 !important;
}

#nexd-app {
    width: 100%;
    height: 1px;
    border: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    display:none;    
    transition: all 1s;
}

#nexd-b-n{
    display: none;
}

#bb-publish-loader, #bb-nexd-sdk-error{
    display: none;
}
#bb-publish-loader{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

#bb-nexd-app-preview{
    justify-content: center;
    transition: all 1s;
    position:relative;    
    margin: 0 auto;
    .adc-ad{
        align-self: center;
    }
}

.bb-image-modal{
    max-width: 443px;
}
@media only screen and (max-width: 476px) {
    .bb-image-modal{
        max-width: 100%;
    }
}