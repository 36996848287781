.bb-breadcrumbs{ 
    margin-left: 3rem!important;
    @include cwd(700){
        margin-left: 1rem!important;
    }
    &-title{
        a{
            color: $bb-text-color-white;        
        }
    }

    &-separator{
        //color: $bb-theme-color;
        color: $bb-text-color-white;
    }

    &-line{
        border: 0.5px solid $bb-color-white;        
        width: 99.4%;
        margin: 0;
    }
}
.nav-parent{
    color: var(--bb-bg-color-gray);
    border-bottom: 1px solid white;
    margin-bottom: 42px;
    .nav-section{
        padding: 20px 40px;
        border-right: 1px solid rgba(133, 133, 133, 0.5);
        &.noborder{
            border-right: unset !important;
        }
        &.-active{
            color: var(--bb-theme-color);
            .nav-label{
                cursor: default;
            }
            
        }
        .nav-icon{
            display: inline-block;
            line-height: 0px;
            border-radius: 50%;
            border: 1px solid;
            font-size: 10px;
            top: 25px;
            cursor: default;
            span{
                display: inline-block;
                padding-top: 50%;
                padding-bottom: 50%;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        .nav-label{
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            margin-left: 8px;
            position: relative;
        }
    }
}