.react-tagsinput {
    background-color: #fff;
    border: 1px solid $bb-color-gray;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 1px $bb-color-gray;
   
  &-tag {
    background-color: $bb-secondary-color;
    border-radius: 10px; 
    color: $bb-text-color-white;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;

    a::before {
        content: " ×";
    }
  }
  
  &-remove {
    cursor: pointer;
    font-weight: bold;        
  }

  
  &-input {
    background: transparent;
    border: 0;
    color: #777;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;    
    width: 98%;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
}