//
// Slider
// --------------------------------------------------

$line-height-computed:20px;
$slider-mark-margin-top:9px;
$slider-bar-side-length:8px;
$slider-handle-diameter:19px;
$slider-handle-border-width:2px;
$slider-handle-dragging-box-shadow:none;
$slider-calibration-diameter:8px;
$slider-calibration-border-width:2px;
$slider-handle-transition:0.3s ease-in-out;
$slider-handle-diameter-half:9.5px;
$slider-bar-side-length-half:4px;
$slider-width-car: 400px;


.rs-slider {
  position: relative;
  margin: 14px;
  width: 180px;

  .rs-tooltip {
    display: block;
    opacity: 1;
    //top: 24px;
    position: absolute;
    //left: -11px;

    &.rs-tooltip-placement-top .rs-tooltip::after {
      margin: auto;
      left: 0;
      right: 0;
    }
  }

  &-disabled {
    opacity: 0.3;
    cursor: not-allowed;

    .rs-slider-bar,
    .rs-slider-handle::before {
      cursor: not-allowed;
    }
  }

  &-with-mark:not(&-vertical) {
    //White space for mark
    margin-bottom: ($line-height-computed + $slider-mark-margin-top);
  }
}

// Bar
.rs-slider-bar {
  height: $slider-bar-side-length;
  border-radius: 4px;
  background-color: #f2f2f5;
  transition: background-color ease-in-out 0.3s;
  cursor: pointer;

 /*  .rs-slider:hover & {
    background-color: grey;
  } */

  .rs-slider-vertical & {
    height: 100%;
    width: $slider-bar-side-length;
  }
}

// Handle
.rs-slider-handle {
  position: absolute;
  top: -50%;
  outline: none;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: $slider-handle-diameter;
    height: $slider-handle-diameter;
    border-radius: 50%;
    border: $slider-handle-border-width solid var(--bb-theme-color);
    background-color: var(--bb-theme-color);
    margin-left: -($slider-handle-diameter-half);
    cursor: pointer;
    /* stylelint-disable */ //Formatted by prettier
    transition: box-shadow $slider-handle-transition, background-color $slider-handle-transition,
      transform $slider-handle-transition;
    /* stylelint-enable */
  }

  &:hover::before,
  &:focus::before {
    box-shadow: 0 0 0 8px rgba(232, 52, 87, 0.25);
  }

  &:active,
  &.active {
    &::before {
      transform: scale(1.2);
    }
  }

  .rs-slider-vertical & {
    top: unset;

    &::before {
      left: calc(($slider-handle-diameter - $slider-bar-side-length) / 2);
      margin-top: -$slider-handle-diameter-half;
    }
  }
}

.rs-slider-handle:hover .rs-tooltip,
.rs-slider-handle.active .rs-tooltip {
    display: block;
    opacity: 1;
    //top: 24px;
    position: absolute;
}

// Mark
.rs-slider-mark {
  position: absolute;
  top: calc($slider-bar-side-length + $slider-mark-margin-top);
  left: -2px;
  white-space: nowrap;

  &-content {
    margin-left: -50%;
  }
}

.rs-slider-mark-last {
  left: auto;
  right: -2px;

  .rs-slider-mark-content {
    margin-left: 50%;
  }
}

// Graduator
.rs-slider-graduator {
  width: 100%;

  ol,
  li {
    list-style: none;
  }

  > ol {
    display: flex;
    padding-left: 0;
    width: 100%;

    > li {
      flex: 1 1 1%;
      position: relative;

      &:last-child::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: $slider-calibration-diameter;
        height: $slider-calibration-diameter;
        border-radius: 50%;
        background-color: #fff;
        box-sizing: border-box;
        border: $slider-calibration-border-width solid #f2f2f5;
        margin-left: -4px;
        top: -1px;

        // Vertical styles
        .rs-slider-vertical & {
          top: unset;
          bottom: -4px;
          margin-left: -1px;
        }
      }

      &:last-child::after {
        right: -4px;

        // Vertical styles
        .rs-slider-vertical & {
          left: 0;
          bottom: unset;
          top: -4px;
        }
      }

      &.rs-slider-pass::before {
        border-color: #3498ff;
      }

      &.rs-slider-active::before {
        visibility: hidden;
      }
    }
  }

  // Vertical styles
  .rs-slider-vertical & {
    display: block;
    height: 100%;

    > ol {
      width: $slider-bar-side-length;
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      padding: 0;

      & > li {
        display: block;
        padding: 0;
      }
    }
  }
}

.swipe-creative-specs{
  .rs-slider-progress-bar{
    background: var(--bb-blue-500) !important;
  }
  .rs-slider-handle{
    background-color: var(--bb-blue-700) !important;
  }

}
// Progress bar
.rs-slider-progress-bar {
  position: absolute;
  height: $slider-bar-side-length;
  border-radius: $slider-bar-side-length-half 0 0 $slider-bar-side-length-half;
  background-color: #3498ff;

  .rs-slider-vertical & {
    width: $slider-bar-side-length;
    border-radius: 0 0 $slider-bar-side-length-half $slider-bar-side-length-half;
  }
}

// Vertical
.rs-slider-vertical {
  height: 100%;

  // Reset all setting
  .rs-slider-mark {
    top: unset;
    bottom: -8px;
    left: calc($slider-bar-side-length + $slider-mark-margin-top);

    &-content {
      margin-left: auto;
    }
  }

  .rs-slider-mark-last {
    bottom: unset;
    top: -8px;
  }
}

.bb-custom-slider {

  .rs-slider-handle {
    top: -10px;
    background-color: var(--bb-theme-color);
    text-align: center;
    padding: 3px;
    margin-left: -16px;
    cursor: pointer;
    transition: box-shadow $slider-handle-transition, background-color $slider-handle-transition,
      transform $slider-handle-transition;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
      box-shadow: 0 0 0 8px rgba(19, 123, 155, 0.25);
    }
    &::before {
      display: none;
    }

    &.active span{
      display: none;
    }
    
  }
  .rs-slider-handle{
    span{
      display: none;
    }
  }

  &.rs-slider-dragging .rs-slider-handle,
  &.rs-slider-dragging .rs-slider-handle:hover {
    box-shadow: none;
    transform: scale(1.2);
  }
}
.bb-car-slider{
  .rs-slider{ width: $slider-width-car; }
  .rs-slider-handle{ background-color: var(--bb-blue-700) !important; }
  .rs-slider-progress-bar{ background-color: var(--bb-blue-500) !important;  }
  .bb-bottom-slider {
    width: $slider-width-car + 48px;
    div{
      text-align: center;
      font-size: 14px;
    }
    div:nth-child(2) {
      margin: 0 10px;
    }
    div:before {
      content: "";
      width: 18px;
      height: 18px;
      background-color: var(--bb-blue-700) ;
      position: absolute;
      border-radius: 50%;
      z-index: 0;
      top: 17px;
    }

    div:nth-child(1):before { left: 0px; }
    div:nth-child(2):before { left: $slider-width-car/2; }
    div:nth-child(3):before { left: $slider-width-car; }

    div:nth-child(1) {margin-left: -28px;}
    div:nth-child(3) {margin-right: -28px;}
  }
}

.bb-custom-slider-green {
  .rs-slider-progress-bar{ background-color: #81B54C !important;}
  .rs-slider-handle {
    background-color: #5D8832 !important;

    &:hover {
      box-shadow: 0 0 0 8px rgba(93, 136, 50, 0.25) !important;
    } 
    // &.active span {
    //   display: block;
    // }
  }

  .disabled {
    pointer-events: none;
  }
}