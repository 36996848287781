$bb-input-box-shadow: none;//2px 2px 10px 1px #ccc;
$bb-input-color: var(--bb-text-color-black);
$bb-input-border-radius: 5px;

.form-control:disabled, .form-control[readonly], .input-disabled{
    background-color: lightgray !important;
    //opacity: 0.8 !important;
    //cursor: not-allowed;
}

.form-control[readonly]{
    background-color: $bb-color-white !important;
}

input[type="checkbox"]:disabled {
    i {
        cursor: not-allowed;
    }
}

.bb-form , .date-picker-input{
    input, .fake-input, .bb-tag-input .react-tagsinput{
        border-radius: $bb-input-border-radius;
        box-shadow: $bb-input-box-shadow;
        color: $bb-input-color;
        &::placeholder{
            color: rgba(1,1,1, 0.4);
        }
        
    }
    .input-no-left-border-radius{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
    }
    .prefix-container{
        display: flex;
        position: relative;
        padding: 0 !important;
        &:focus-within {
            border: 1px solid #80bdff;
            border-radius: 6px;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            .input-group-prepend, .input-group-text{
                border: 0;
                outline: 0;
            }
        }
        input{
            margin: 0 !important;
            &:focus{
                border: 0;
                box-shadow: none;
            }
        }
    }
    .fake-input{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &-mw{
        input, .bb-select-input, textarea:not(.bb-creative-tag){
            max-width: 500px;
        }
       
        input[type=number]
        {
            max-width: 200px;
        }
    }

    input[type=checkbox]
    {
        padding: 10px;
        max-width: 24px;
        max-height: 24px;

        &:focus{
            border: none;
            box-shadow: $bb-input-box-shadow;
        }
    }

    button{
        max-width: 200px;
        padding: 5px;
    }

    .input-ctc{
        border-radius: 0 !important;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        // max-width: 100px;
        text-overflow: ellipsis;
    }
    .ctc-element:hover{
        color: $bb-secondary-color !important
    }
    .label-header{
        font-size: 1rem;
        color: var(--bb-text-color-grey-10);
        font-weight: bold;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    .label-sub-header{
        color: $bb-secondary-color;

    }

    .bb-date-picker{
        width: 135px;
    }
    .bb-time-picker{
        width: 85px;
    }

    .input-group-prepend{
        height: calc(1.5em + .75rem + 2px);
        .input-group-text{
            box-shadow: $bb-input-box-shadow;
            color: $bb-input-color;
            font-size: 20px;
            border-radius: 5px 0 0 5px;
            background-color: white;
            font-size: 20px;
        }
    }
}

/* Image preview */
.bb-preview-container{
    min-width: 120px;
    //min-height: 120px;
    overflow: hidden;
    align-self: center;
    flex-grow: 1;
    img{
        width: auto;
        max-width: 100%;
        // height: 100%;
        margin: 0 auto;
    }
}
.bb-logo-preview-container{
    max-width: 120px;
    max-height: 120px;
}

.bb-card-preview-container{
    max-width: 300px !important;
    max-height: 300px !important;
}

.bb-preview-container{
    &-300x300{
        max-width: 300px;   
        max-height: 150px;
    }
    &-300x250{
        max-width: 300px;   
        max-height: 250px;
    }
}
#react-qrcode-logo{
    border-radius: 20px 0px;
}
.bb-image-upload-container{    
    background-color: $bb-bg-secondary-color;
    min-width: 150px;
    min-height: 150px;
    overflow: hidden;
    display: flex;
    position: relative;
    flex-direction: column;     
    justify-content: center;
    // border: 1px solid black;
    box-shadow: $bb-input-box-shadow;
    border-radius: $bb-input-border-radius;
    border: 1px solid $bb-bg-secondary-color;
    .bb-remove-image{
        text-align: center;
        padding: 4px;
        z-index: 999;
        position: absolute;
        top: 0;
        right: 0px;
        background: rgba(236, 21, 8, 0.8);
        width: 34px;
        border-radius: 0 10px;
        // visibility: hidden;
        cursor: pointer;
    }
    .video-preview{
        max-height: 300px;
        .fa-video-camera{
            &.right-bottom{
                right: 6px;
                bottom: 6px;
            }
        }
    }
   

    .bb-input-filename {
        text-align: center;
        width: 100%;
        padding: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;    
        max-width: 200px;    
      }
}

.bb-logo-upload{
    max-width: 150px;
    // max-height: 150px;
}


.bb-upload-container{
    &-300x300{
        max-width: 300px;           
    }
    &-300x250{
        max-width: 300px;           
    }
}

.bb-input-image{
    display: none;

    & + label{
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        height: 100%;        
        margin:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .bb-input-label{
        text-align: left !important;
        padding-left: 1rem!important;
    }
    .bb-form-input-label{
        text-align: left !important;
    }

    .bb-form-mw{        
       
        input[type=number]
        {
            min-width: 200px;            
        }
    }
}
    
.bb-form-input-label{
    // text-align: right;
    min-width: 150px;
}

.bb-input-label{
    text-align: right;
}

.bb-profile{
    &-buttons{
        max-width: 240px;
        align-self: center;
        width: 100%;
    }

    &-header{
        text-align: center;
        font-size: xx-large;
    }
}

.link-to-profile{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 130px;
}

.profile-name-size{
    font-size: 16px;
}

.creative-archived-message{
    background-image: linear-gradient(to bottom right,rgba(239,154,0,.3) 25%,transparent 0,transparent 50%,rgba(239,154,0,.3) 0,rgba(239,154,0,.3) 75%,transparent 0,transparent);
    background-size: 56.57px 56.57px;
    color: black;
    font-weight: bold;
    background-color: white;
}
.user-select{
    &.border_5px{
        .bb-select__control{
            max-width: 350px !important;
            border-radius: 5px !important;
        }
    }
    &.mw-350{
        .bb-select__control{
            min-width: 350px !important;
        }
    }
    &.mw-510{
        .bb-select__control{
            min-width: 510px !important;
        }
    }
    &.mw-250{
        .bb-select__control{
            min-width: 250px !important;
        }
    }
}
/* For React select container styles */
.bb-select-error{
    .bb-select__control{
        border: 1.5px solid $bb-error-color;
    }
}
.bb-select{
    &__small{
        min-width: 200px;
        max-width: 350px;    
    }
    &__control {
        background-color: $bb-color-white !important;
        box-shadow: $bb-input-box-shadow;
        border-radius: $bb-input-border-radius !important;
        max-width: 500px;
        
        &--is-disabled {
            //opacity: 0.7;
            background: lightgray !important;
            border-radius: $bb-input-border-radius;
        }
    }

    &--is-disabled {
        //opacity: 0.7;
        background: lightgray !important;
        border-radius: $bb-input-border-radius;
        max-width: 500px;
    }

    &-status{
        &__control{
            background-color: transparent !important;
            border-radius: 0% !important;
            border-style: none !important;
            border-width: 0 !important;
        }
        &__indicator-separator {
            display: none;
        }
    }

    &__menu{
        max-width: 500px;
        max-height: 200px;
        z-index: 1000 !important;
        &-list{ 
            max-height: 200px !important;
            font-size: 14px;
            &--is-multi{
                max-height: 200px !important;
            }
        }
        color: $bb-text-color-black;
        &-list::-webkit-scrollbar{
            width: 1.25em; 
            height: 0;
            //border-radius: $bb-input-border-radius;
            margin-right: 20px;
            box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
            &-thumb{
                height: 3em;
                border: 0.3em solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                -webkit-border-radius: 0.5;
                background-color: var(--bb-bg-color-tertiary-20);
                border-radius: $bb-input-border-radius;            
            }
        
            &-track{
      
                border-radius: $bb-input-border-radius;
                padding: 10px;
            }
        }
    }

    &__input{
        input{
            box-shadow: none;
            border:none;
        }
    }

    &__single-value{
        color: $bb-input-color !important;
        // text-transform: capitalize;
        width: 95%;
    }

    &-input{
        border-radius: $bb-input-border-radius;
        box-shadow: $bb-input-box-shadow;
        color: $bb-input-color;
    
        background-image:
        url("'~/public/images/arrow.svg"),
        linear-gradient(to right, $bb-color-gray, $bb-color-gray);
        background-position:
        calc(100% - 7px) calc(1em - 6px), 
        calc(100% - 2.5em) 0.5em;
        background-size:
        20px 20px, 
        1px 1.5em;
        background-repeat: no-repeat;  
        margin: 0;      
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none; 
        -moz-appearance: none;       
    }

    &__multi-value{
        background-color: $bb-secondary-color !important;
        border-radius: 5px !important;
        color: $bb-text-color-white;

        &__label{
            color:$bb-text-color-white !important;
        }
        &__remove{
            color: $bb-text-color-white !important;
        }

        &--is-disabled{
            .bb-select__multi-value__remove{
                display:none;
            }
            .bb-select__multi-value__label{
                padding-right: 6px;
            }
        }
    }

    &__loading-indicator {
        span{
            background: $bb-secondary-color !important;
        }
    }

}
.bb-select-w-unset .bb-select{
    &__control, &__menu, &--is-disabled{ max-width: unset; }
}
/* React dropdown */

.react-dropdown-options-menu{
    top: 10px;
    background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
    margin-bottom: 8px;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    max-width: 500px;
    color: $bb-text-color-black;
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
  }
  
  .react-dropdown-option{
      background-color: transparent;
      color: inherit;
      cursor: default;
      display: block;
      font-size: inherit;
      padding: 8px 12px;
      width: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      box-sizing: border-box;

      &:hover{
        background: #deebff;
      }

      &.is-selected{
        background: #2684ff;
        color: white;
      }
  }
  
  .Dropdown-control{
    padding: .375rem .75rem;
  }

  .Dropdown-disabled{
    background: lightgray;   
    border-radius: $bb-input-border-radius;
  }
  
  /* Form radio or radio button */
  .radio-item {
    input {
      &[type=radio] {
        padding: 10px;
        max-width: 24px;
        max-height: 24px;
        box-shadow: none;     
        display: none;
      }
    }
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
    label {
      font-weight: normal;
      &:before {
        content: " ";
        display: inline-block;
        position: relative;
        top: 5px;
        margin: 0 5px 0 0;
        width: 24px;
        height: 24px;
        border-radius: 11px;
        border: 2px solid transparent;
        background-color: $bb-color-white;
      }
    }
    .radio-item-bg_black {
        &:before {
            background-color: #343a40 !important;
        }
        
    }
    input {
      &[type=radio]:checked + label::after {
        border-radius: 11px;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 12px;
        left: 9px;
        content: " ";
        display: block;
        background: $bb-text-primary-color;
      }
      &[type='checkbox'] {
        display: none;
      }
      &[type=checkbox] {
        &:checked + label::after {
          border-radius: 11px;
          width: 18px;
          height: 18px;
          position: absolute;
          top: 12px;
          left: 9px;
          content: " ";
          display: block;
          background: $bb-text-primary-color;
        }
        padding: 10px;
        max-width: 24px;
        max-height: 24px;
        box-shadow: none;
      }
    }
  }

.bb-form-right-align-2-col{
    .form-row > div:not([flex-end = "false"]){        
        align-self: flex-end;        
    }
   
    .bb-form-input-label{
        text-align:right; 
        max-width: 150px;
        @include cwd-min-max(992,1271){
           text-align:left; 
        }
        @include cwd-min-max(782,991){
           text-align:right; 
        }
        @include cwd-max(781){
           text-align:left; 
        }
    }
    .bb-form-dsp-label{
        @include cwd-min-max(992,1275){
            text-align:left; 
         }
         @include cwd-min-max(784,991){
            text-align:right; 
         }
         @include cwd-max(783){
            text-align:left; 
         }
    }
    
}

.bb-form-right-align-1-col{
    .form-row > div:not([flex-end = "false"]){        
        align-self: flex-end;        
    }
    .bb-form-input-label{
        text-align:right; 
        max-width: 150px;
    }
}

// .bb-select__option{
//     text-transform: capitalize;
// }

.video-preview-sm{
    .fa-video-camera{
        &.right-bottom{
            right: 11px;
            bottom: 7px;
            color: $bb-text-color-white;
        }
    }
}

.maxWidth-300{
    max-width: 300px !important;
} 

.maxWidth-mc{
    max-width: min-content !important;
} 

/* Light Theme */
$bb-primary-font-v2: "Ubuntu";

.bb-primary-button{
    outline: none !important;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 1%;
    font-family: $bb-primary-font-v2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    min-width: 69px;
    min-height: 33px;
    color: var(--bb-primary-background-2);
    background: var(--bb-primary-pink-1);
    border: 1px solid var(--bb-primary-pink-1);
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background: var(--bb-primary-pink-05);
        border: 1px solid var(--bb-primary-pink-05);
    }
    &:active {
        background: var(--bb-primary-pink-0);
        border: 1px solid var(--bb-primary-pink-0);
    }
    &:disabled{
        background: var(--bb-primary-text-3);
        border: 1px solid var(--bb-primary-text-3);
    }
    i{
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
  }

  
.bb-secondary-button{
    outline: none !important;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 1%;
    font-family: $bb-primary-font-v2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    min-width: 69px;
    min-height: 33px;
    color: var(--bb-primary-pink-1);
    background: transparent;
    border-radius: 5px;
    border: 1px solid var(--bb-primary-pink-1);
    &:hover {
        color: var(--bb-primary-pink-0);
        border: 1px solid var(--bb-primary-pink-0);
    }
    &:active {
        color: var(--bb-primary-background-2);
        border: 1px solid var(--bb-primary-pink-1);
        background: var(--bb-primary-pink-1);
    }
    &:disabled{
        color: var(--bb-primary-text-3);
        border: 1px solid var(--bb-primary-text-3);
    }
    i{
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
  }

  .bb-tertiary-button{
    outline: none !important;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 1%;
    font-family: $bb-primary-font-v2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    color: var(--bb-primary-pink-1);
    background: transparent;
    border: none;
    &:hover {
        color: var(--bb-primary-pink-05);
    }
    &:active {
        color: var(--bb-primary-pink-0);
    }
    &:disabled{
        color: var(--bb-primary-text-3);
    }
    i{
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
  }

  
.bb-assist-primary-button{
    outline: none !important;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 1%;
    font-family: $bb-primary-font-v2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    min-width: 51px;
    min-height: 29px;
    color: var(--bb-primary-background-2);
    background: var(--bb-secondary-functionality-1);
    border: 1px solid var(--bb-secondary-functionality-1);
    border-radius: 5px;
    &:hover {
        background: var(--bb-secondary-functionality-05);
        border: 1px solid var(--bb-secondary-functionality-05);
    }
    &:active {
        background: var(--bb-secondary-functionality-0);
        border: 1px solid var(--bb-secondary-functionality-0);
    }
    &:disabled{
        background: var(--bb-primary-text-3);
        border: 1px solid var(--bb-primary-text-3);
    }
  }

  
.bb-assist-secondary-button{
    outline: none !important;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 1%;
    font-family: $bb-primary-font-v2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    min-width: 51px;
    min-height: 29px;
    color: var(--bb-secondary-functionality-1);
    background: transparent;
    border-radius: 5px;
    border: 1px solid var(--bb-secondary-functionality-1);
    &:hover {
        color: var(--bb-secondary-functionality-0);
        border: 1px solid var(--bb-secondary-functionality-0);
    }
    &:active {
        color: var(--bb-primary-background-2);
        border: 1px solid var(--bb-secondary-functionality-1);
        background: var(--bb-secondary-functionality-1);
    }
    &:disabled{
        color: var(--bb-primary-text-3);
        border: 1px solid var(--bb-primary-text-3);
    }
  }
  
  .bb-label-color{
      color: var(--bb-primary-text-3);
  }

  .bb-select-light__control{
    background: var(--bb-primary-background-2);
    border: 1px solid var(--bb-primary-background-0);
    max-height: 29px !important;
    min-height: 29px !important;
    min-width: 160px;
    border-radius: 5px !important;
    font-family: $bb-primary-font-v2 !important;
    font-size: 13px !important;
    line-height: 21px !important;    
    color: var(--bb-primary-text-1) !important;
    &.max-width{
        min-width: 210px !important;
    }
    cursor: pointer;
    &.bb-select-light__control--is-disabled{
        background: var(--bb-primary-background-05) !important;
        .bb-select-light__value-container{
            div{
                color: var(--bb-primary-text-4) !important;
            }
        }
    }
    &:hover{
        border: 1px solid var(--bb-primary-text-4) !important;
    }
    &--is-focused{
        border: 1px solid var(--bb-secondary-functionality-1) !important;
        box-shadow: 2px 2px 8px rgba(20, 161, 255, 0.15) !important;
    }

    .bb-select-light__value-container{
        padding: 4px 12px !important;
        max-height: 28px !important;
        div{
            margin:0px;
            padding: 0px;
            color: var(--bb-primary-text-1) !important;
        }
        .bb-select-light__placeholder{
            text-align: center;
            align-items: center;
            display: flex;
        }
    }
    .bb-select-light__indicators{
        padding: 4px 12px;
        .bb-select-light__indicator-separator{
            display: none;
        }
        .bb-select-light__indicator{
            padding: 0px;
            i{
                color: var(--bb-primary-text-2) !important;
            }
        }
        .bb-select-light__clear-indicator{
            width: 18px;
            border-right: 1px solid;
            margin-right: 4px;
            padding-right: 0px;
            color: var(--bb-primary-text-2) !important;
        }
    }   
  }
  .bb-select-light__menu-list{
        background: var(--bb-primary-background-2);
        border: 1px solid var(--bb-primary-text-4);
        box-sizing: border-box;
        box-shadow: 2px 2px 8px rgba(32, 33, 36, 0.03);
        border-radius: 5px;
        overflow-x: hidden;
        padding: 0px !important;
        &::-webkit-scrollbar{
            width: 1.25em; 
            height: 0;
            //border-radius: $bb-input-border-radius;
            margin-right: 20px;
            box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
            &-thumb{
                height: 3em;
                border: 0.3em solid rgba(0, 0, 0, 0);
                background-clip: padding-box;
                -webkit-border-radius: 0.5;
                background-color: var(--bb-bg-color-tertiary-20);
                border-radius: $bb-input-border-radius;            
            }
        
            &-track{
      
                border-radius: $bb-input-border-radius;
                padding: 10px;
            }
        }
    .bb-select-light__option{        
        font-family: $bb-primary-font-v2 !important;
        // max-width: 90%;
        font-size: 13px;
        line-height: 21px;
        color: var(--bb-primary-text-1);
        border-bottom: 1px solid var(--bb-primary-background-0);
        // margin-left: 12px;
        padding: 4px 12px;
        // padding-left: 0;
        margin:0;
        &.bb-select-light__option--is-focused, &:hover {
            background-color: var(--bb-primary-background-1);
        }  
        &.bb-select-light__option--is-selected{
            background-color: var(--bb-secondary-functionality-1);
            color: var(--bb-primary-background-2);
        }
    }
}

.mx-610{
    max-width: 610 !important;
    padding-left: 0px !important;
}
.bb-radio-container{
    font-size: 16px;
    .bb-radio{
        height: 24px;
        width: 24px;
    }
    input[type='radio']:checked:after {
        width: 24px;
        height: 24px;
        border-radius: 15px;
        position: relative;
        background-color: #E83457;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
        outline: thin solid #2A314380;
    }
    input:disabled:checked:after{
        background-color: #D3D3D3 !important;
    }
}