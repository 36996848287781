body.react-confirm-alert-body-element {
    overflow: hidden;
  }

  .bb-confirm-box {
    min-width: 300px;
    max-width: 400px;
    background: $bb-bg-secondary-color;
    padding: 20px;
    border-radius: 10px;
    color:$bb-text-color-black;
    max-height: 500px;
    overflow: auto;
    &-md{
      min-width: 650px;
      max-width: 750px;
      .bb-confirm-box-content{
        max-height: 320px;
        overflow-y: auto;
      }
    }
  }
  
  .bb-summary-box{
    min-width: 700px;
    max-width: 900px;
    background: $bb-bg-tertiary-color;
    padding: 20px;
    border-radius: 10px;
    color: $bb-text-color-white;
  }
  
  .react-confirm-alert
  {
    overflow: auto;
    max-height: 100%;
    display: flex;
    align-items: center;
    
      &-blur {
        filter: url(#gaussian-blur);
        filter: blur(2px);
        -webkit-filter: blur(2px);
      }
      &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        background: rgba(1, 1, 1, 0.6);
        display: flex;
        justify-content: center;
        -ms-align-items: center;
        align-items: center;
        opacity: 0;
        -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
        -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
        -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
        animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
        z-index: 9999;
      }
      
      &-body {
        font-family: Arial, Helvetica, sans-serif;
        width: 400px;
        padding: 30px;
        text-align: left;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
        color: black;

        h1{
            margin-top: 0;
        }

        h3 {
            margin: 0;
            font-size: 16px;
          }
      }

      &-svg {
        position: absolute;
        top: 0;
        left: 0;
      }

      &-button-group {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;

        button {
            outline: none;
            background: #333;
            border: none;
            display: inline-block;
            padding: 6px 18px;
            color: #eee;
            margin-right: 10px;
            border-radius: 5px;
            font-size: 12px;
            cursor: pointer;
          }
      }
  }

  @include keyframes(react-confirm-alert-fadeIn) {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  