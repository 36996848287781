.slider {
	position: relative;
	width: 100%;
	min-height: 610px;
	max-height: 635px;
	overflow: hidden;
	border-radius: 10px;

	.audience-modal-header{
		background: whitesmoke;
		color: black;
		position: relative;
		height: 42px;
	}

	.slider-card-scroll-curated {
		height: calc(100% - 42px) !important;;
		overflow-y: auto;
		background: whitesmoke;
    	color: black;
	  }

	&.creative-slider{
		height: 392px !important;
		.handle-direction{
			position: absolute;
    		bottom: 10px;
    		right: 17px;
		}
		
		.slider-card-scroll{
			height: calc(100% - 104px) !important;
   			overflow-y: auto;
		}
		.bb-card-footer-container {
			bottom: 8px !important;
			background: transparent !important;
		}  
		.video-image{
			position: absolute;
    		top: 30%;
    		width: 100px;
    	
		}
	}

	&.creative-slider-user{
		min-height: 392px !important;
	}
	
	& a {
		& svg{
			width: 10px !important;
		}
		& polygon{
			fill: white !important;
		}
		&.previousButton, &.nextButton {
			background: rgba(0, 0, 0, 0.7) ;
			border-radius: 50%;
			font-size: 22px;
			line-height: 0;
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			transition: all .3s linear;
			z-index: 1;
			color: #333;
			padding: 0px 10px;
			text-decoration: none;
			backface-visibility: hidden; /* prevent jump effect when scaling */

			&:not(.disabled):hover {
				transform: translateY(-50%) scale(1.25);
				cursor: pointer;
			}
		}

		&.previousButton {
            left: 2px;
		}

		&.nextButton {
            right: 2px;
		}
	}
}

.slide {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;

	&.hidden {
		visibility: hidden;
	}

	&.previous {
		left: -100%;
	}

	&.current {
		left: 0;
	}

	&.next {
		left: 100%;
	}

	&.animateIn,
	&.animateOut {
		transition: all 0.5s ease;
	}

	&.animateIn {
		&.previous,
		&.next {
			left: 0;
			visibility: visible; 
		}
	}

	&.animateOut {
		&.previous {
			left: 100%;
		}

		&.next {
			left: -100%;
		}
	}
}
.slider-card-scroll, .slider-card-scroll-curated { 
&::-webkit-scrollbar {
	width: 10px; /* Total width including `border-width` of scrollbar thumb */
	height: 0;
	box-shadow: inset 0 0 50px var(--bb-bg-color-tertiary);
	border-radius: 10px;       
	&-thumb{
		height: 10px;
		border: 2px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
		background-clip: padding-box;
		-webkit-border-radius: 0.5;
		background-color: var(--bb-theme-color);
		border-radius: 5px;
	}

	&-track{
		border-radius: 5px;
		padding: 5px;
	}
}
}