$bb-menu-pink: var(--bb-theme-color);
$bb-menu-pink-rgb: var(--bb-theme-color-rgb);
$bb-menu-yellow: var(--bb-theme-color-secondary);
$bb-menu-blue: #0CD3DA;
$bb-menu-green: #6D9E3B;
$bb-menu-winehouse: #ba2d0b;

$hoverOpacity: 0.5;
/* Submenu styles */

.bb-menu-circle {
    background: $bb-color-black;
    padding: 4px;
    color: $bb-text-color-white;
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    text-align: center; 
    font-size: 24px;
  }
  
  .bb-menu-circle-light{
    background: $bb-text-color-white;
    color: $bb-color-black;;
    width: 20px;
    height: 19px;
    font-size: 12px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center; 
    padding: 4px;
  }
  
  .bb-sub-menu, .rs-bb-sub-menu {
    /* border-radius: 5px;
    background: $bb-color-black;
    padding: 5px;  */

    &-more{
      background: rgba(0, 0, 0, 0.8);
      border-radius: 0 5px 5px 5px;
    }

    &-item{
        cursor: pointer;
        padding: 5px;
        // height: 28px;
        color: $bb-text-color-white;
        font-size: 14px;
        text-align: center;
        border-bottom: 1px solid var(--bb-bg-color-tertiary-20);
        &:last-child { border: 0 !important; }

        &:hover{
            color: $bb-theme-color;
        }
        .bb-disabled-menu{
          color: $bb-text-color-gray;
          pointer-events: none;
        }
        &-disabled{
          color: var(--bb-color-disabled) !important; 
        }
    }
  }
  

  /* Sidebar menu styles */
  .bb-menu-logo {
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100px;
    height: 100%;

    &-container{
        .img-top{
            opacity: 0;
            position: absolute;
            top: 0;
            z-index: 99;
            transition: all 1s;
        }
        &:hover{
            .img-top{
                opacity: 1;
            }
        }
    }
  }
  
  #side-nav-overlay{
    position: fixed;
    display: none;
    z-index: 5;
    left: 0;
    top: 0;
    background-color: $bb-bg-tertiary-color;
    opacity: 0.2;
    width: 100%;
    height: 100%;
  }
  .side-nav {
    height: 100vh;
    width: 100%;
    max-width: 250px;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: $bb-bg-tertiary-color;
    overflow-x: hidden !important;
    //overflow-y: hidden;  
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    &-mobile{
      top: 0;
      position: fixed;
      width: 100vw;
      z-index: 1005;
      height: 70px;
      padding-right: 30px;
      padding-left: 21px;
      background-color: $bb-bg-tertiary-color;
    }
    &:hover{
        overflow-y: auto;
    }
    a , div.bb-menu-item-not-link{
        text-align: left;
        padding: 10px 10px 10px;
        /* padding-left: 50px; */
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        color: $bb-text-color-white !important;
        display: block;
        border-radius: 5px;
        cursor: pointer;
    }

    //@include cwd(700){
        transform: translateX(0%);
        transition: all 0.5s;
        overflow-y: hidden;
    //}

    //@include cwd-max(699){
        /* transform: translateX(-100%);
        transition: all 0.5s;
        overflow-y: auto; */
    //}
    .menu-ec-arrow, &-mobile .menu-ec-arrow{
          display: inline-block;
          border-radius: 50%;
          vertical-align: middle;
          cursor: pointer;
          &-open {
              display: block;
              width: 32px;
              height: 26px;
              background: var(--bb-bg-color-tertiary-20);
              border-radius: 4px;
              align-self: center;
            &:after {
                content: '';
                display: block;
                margin-top: 9px;
                margin-left: 7.5px;
                width: 8px;
                height: 8px;
                border-top: 3px solid white;
                border-right: 3px solid white;
                transform: rotate(45deg);
            }
          }
          &-close{
              display: block;
              width: 30px;
              height: 26px;
              background: var(--bb-bg-color-tertiary-20);
              border-radius: 4px;
              align-self: center;
              &:after{
                content: '';
                display: block;
                margin-top: 9px;
                margin-left: 10px;
                width: 8px;
                height: 8px;
                border-top: 3px solid var(--bb-bg-color-tertiary);
                border-right: 3px solid var(--bb-bg-color-tertiary);
                transform: rotate(225deg);
          }
        }
      }
  }

  .dropdown{
    display: none;
    position: relative;
    background-color: transparent !important;   

    &.active{
        display: block;
    }
    a, div.bb-menu-item-not-link{
        font-size: 14px !important;
        padding: 5px 5px 5px;
        margin-left: 30px;
        text-align: left;
        margin-top: 5px;
        cursor: pointer;
    }
  }

  .bb-menu-item{
      &.active{
        transition: all 0.5s;
      }
  }

  .menu-item{
      &_pink{
          &.active{
            background-color: $bb-menu-pink;
            color: var(--bb-text-color-vendor);
          }
          &:hover:not(.active) {
            background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
          }
      }
      &_blue{
        &.active{
          background-color: $bb-menu-blue;
        }
        &:hover:not(.active) {
            background-color: rgba($bb-menu-blue, $hoverOpacity);
        }
    }
    &_winehouse{
        &.active{
            background-color: $bb-menu-winehouse;
            background-image: linear-gradient(319deg, #ba2d0b 0%, #fe7f2d 37%, #ffbf46 100%);
        }
        &:hover:not(.active) {
            background-color: rgba($bb-menu-winehouse, $hoverOpacity);
        }
    }
    &_yellow{
        &.active{
          background-color: $bb-menu-yellow;
        }
        &:hover:not(.active) {
            background-color: rgba($bb-menu-yellow, $hoverOpacity);
        }
    }
    &_green{
        &.active{
          background-color: $bb-menu-green;
        }
        &:hover:not(.active) {
          background-color: rgba($bb-menu-green, $hoverOpacity);
        }
    }
  }

  .dropdown-content {
    &.active::before {
      content: "";
      height: 20px;
      width: 20px;
      float: left;
      font-size: 30px;
      margin-left: -25px;
      border-radius: 5px;
      display: block;
      -webkit-animation-name: swing;
      animation-name: swing;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    &.color_pink {
      &.active::before {
        background-color: $bb-menu-pink;
        color: var(--bb-text-color-vendor);
        text-shadow: -1px 0 $bb-menu-pink, 0 1px $bb-menu-pink, 1px 0 $bb-menu-pink, 0 -1px $bb-menu-pink;
      }
      &:hover:not(.active) {
        background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
      }
    }
    &.color_blue {
      &.active::before {
        background-color: $bb-menu-blue;
        text-shadow: -1px 0 $bb-menu-blue, 0 1px $bb-menu-blue, 1px 0 $bb-menu-blue, 0 -1px $bb-menu-blue;
      }
      &:hover:not(.active) {
        background-color: rgba($bb-menu-blue, $hoverOpacity);
      }
    }
    &.color_winehouse {
      &.active::before {
        background-color: $bb-menu-winehouse;
        background-image: linear-gradient(319deg, $bb-menu-winehouse 0%, #fe7f2d 37%, #ffbf46 100%);
        text-shadow: -1px 0 $bb-menu-winehouse, 0 1px $bb-menu-winehouse, 1px 0 $bb-menu-winehouse, 0 -1px $bb-menu-winehouse;
      }
      &:hover:not(.active) {
        background-color: rgba($bb-menu-winehouse, $hoverOpacity);
        background-image: linear-gradient(319deg, $bb-menu-winehouse 0%, #fe7f2d 37%, #ffbf46 100%);
      }
    }
    &.color_yellow {
      &.active::before {
        background-color: $bb-menu-yellow;
        text-shadow: -1px 0 $bb-menu-yellow, 0 1px $bb-menu-yellow, 1px 0 $bb-menu-yellow, 0 -1px $bb-menu-yellow;
      }
      &:hover:not(.active) {
        background-color: rgba($bb-menu-yellow, $hoverOpacity);
      }
    }
    &.color_green {
      &.active::before {
        background-color: $bb-menu-green;
        text-shadow: -1px 0 $bb-menu-green, 0 1px $bb-menu-green, 1px 0 $bb-menu-green, 0 -1px $bb-menu-green;
      }
      &:hover:not(.active) {
        background-color: rgba($bb-menu-green, $hoverOpacity);
      }
    }
  }

  .menu-burger-icon {
    display: block;
    width: 28px;
    z-index: 98;
    position: absolute;

    @include cwd(700){
        display: none;   
    }
  }

  
  .menu-close-button {
    width: 24px;
    display: block;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 2;

    @include cwd(700){
        display: none;   
    }
  }


  .bb-user-role{
    background: $bb-theme-color;
    color: var(--bb-text-color-vendor);
    padding: 0 4px;
    border-radius: 5px;
  }

  .bb-greet-user {
    *{
      max-width: 115px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

 
@media only screen and (max-width: 699px) {
    .bb-overlay-main{
      opacity: 0.5;
      overflow: hidden;
      *{
        pointer-events: none;
      }
    }    
}

.bb-menu-online-dot{
  position: relative !important;
  margin-top: 0px !important;
  width: 10px !important;
  height: 10px !important;
  border: 1px solid #484D5C !important;
  margin-left: 5px;
}