$bb-menu-pink: var(--bb-theme-color);
$bb-menu-pink-rgb: var(--bb-theme-color-rgb);
$bb-menu-yellow: var(--bb-theme-color-secondary);

$bb-menu-green: var(--bb-green);
$bb-menu-winehouse: #ba2d0b;

$hoverOpacity: 0.5;

#bb-user-info-container, .bb-menu-logo-container{
  position: relative;
  background: var(--bb-bg-color-tertiary);
  z-index: 99;
  width: 250px;
}
#bb-user-info-container{
  position: absolute;
  padding: 0 20px;
  left: 0px;
  bottom: 0;
}
.bb-menu-toggle{
    transition: all 0.3s;
    padding: 10px 10px 10px;
    &-button{
      width: 75px;
      height: 25px;
      display: flex;
      align-items: center;
      font-size: 12px;
      //padding-left: 10px;
      background: var(--bb-bg-color-tertiary-20);
      cursor: pointer;
      text-align: center;
      justify-content: center;
      &-active{
        background: var(--bb-theme-color);
        color: var(--bb-text-color-vendor);
      }
      &:hover:not(.active) {
        background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
      }
    }
  }
  
  /* Showcase menu */
  .bb-buddy-menu {
    transition: all 0.3s;
    width: 210px;
    animation: fadein 0.35s ease-in;
  }

  .menu-dropdown{
      width: 210px;
      transition: all 0.3s;
      
    .bb-menu-item .dropdown-icon{
      -webkit-transition: transform 0.3s ease-in-out;
      -moz-transition:transform 0.3s ease-in-out;
      -ms-transition:transform 0.3s ease-in-out;
    }
    .bb-menu-item .dropdown-icon.active{
      transform: rotate(90deg);
    }
  }

  .bb-menu-container{
    transition: all 0.5s;
    //min-height: 100px;
  }

  .menu-list-container{
    margin-bottom: 103px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .bb-showcase-menu {
    //border: 1px solid #858585;
    color: var(--bb-text-color-white);
    //transform: translateX(120%);

    transition: all 0.3s;
    width: 210px;
    //display: none;

    animation: fadein 0.35s ease-in;
    &__heading{
        &:active, &:focus{
            outline: none;
        }
    }
  
    a {
        color: var(--bb-theme-color);
    }
  
    &__item{ 
        width: 210px;
    } 
  
    &__button {
        cursor: pointer;
        padding: 10px 10px 10px;
        text-align: left;
        border: none;
        position: relative;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;      
    
      &:active, &:focus{
          outline: none;
      }
      &:hover{
        background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
      }
    
      
      &:after{
          display: inline-block;
          content: '';
          height: 10px;
          width: 10px;
          //margin-left: 105px;
          border-bottom: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(-45deg);
          transition: all 0.35s;
      } 
    
      &[aria-expanded='true']::after,&[aria-selected='true']::after{
          transform: rotate(45deg);
          margin-bottom: 4px;
      } 
     
    }
    &__panel {
      animation: fadein 0.35s ease-in;
      font-size: 14px !important;
        //@extend: 5px 5px 5px;
        //margin-left: 30px;
        text-align: left;
        margin-top: 5px;
        cursor: pointer;

        label>input[type="radio"], label>input[type="checkbox"] {
            display: none;
            cursor: pointer;
        }
    }

    &__sub-item{
            background: transparent;
            border: none;
            font-size: 14px;
            padding: 2px;
            cursor: pointer;        
    }

    label.list-group-item {
        background: transparent;
        border: none;
        font-size: 14px;
        padding: 0px 0px 0px 15px;
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
       
        &.active::before { 

            content:'';
            display: block;
            transform: rotate(45deg);
            height: 11px;
            width: 7px;
            margin-right: 5px;
            border-bottom: 3px solid var(--bb-blue-color);
            border-right: 3px solid var(--bb-blue-color);

            -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
          -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
     
          }          
          &:hover:not(.active) {
            background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
          }
    }
    a{
      color: white;
      font-size: 14px;
      padding: 0px;
    
  }
  
    /* label.list-group-item.active {
        background: transparent;
        background: rgba(255, 255, 255, 0.8);
        color: #e61256;
    } */
    
    label.list-group-item:hover {
        background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
    }
  
  }

  .bb-menu-burger-icon{
    align-self: center; 
    padding: 9px;   
    border-radius: 50%;
    font-size: 16x;
    height: 36px;
    max-width: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--bb-theme-color);
    background: white;

    &:hover{
      outline: none;
      background-color: var(--bb-theme-color);
      color: white;
    }
  }

  #bb-menu-burger-icon-e{
    display: none;
    margin-right: 11px;
    &-mobile{ display: block; }
  }
  /* #bb-menu-burger-icon-c{  
    margin-right: 6px;
  } */


  .dropdown-line{
    display: none;
    border-radius: 5px;
    margin-top: -2px !important;
    margin-bottom: -2px !important;
    margin-left: 0px;

    &.active{
        display: block;
        &.color_pink{
            border: 3px solid $bb-menu-pink;
            background: $bb-menu-pink;
            color: var(--bb-text-color-vendor);
        }
        &.color_winehouse{
            background-color: $bb-menu-winehouse;
            background-image: linear-gradient(319deg, #ba2d0b 0%, #fe7f2d 37%, #ffbf46 100%);  
            border: 3px solid #fe7f2d;    
        }
        &.color_yellow{
            border: 3px solid $bb-menu-yellow;
        }
        &.color_blue{
            border: 3px solid var(--bb-secondary-functionality-0);
            background: var(--bb-secondary-functionality-0);
        }
        &.color_green{
            border: 3px solid $bb-menu-green;
        }
    }

  }

  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  
  @keyframes fadein {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
  }