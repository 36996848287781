@media (max-width: 1430px) {
    .desktop-live-container{
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.sticky-qr-custom{
    position: sticky;
    top: 0;
    &._20{
        top: 20px !important;
    }
}

.mars-phone{
    width: 352px;
    position: absolute;
    user-select: none;
    top: 0;
    left: 0;
    pointer-events: none;
}

.preview-phone-outline{
    width: 340px;
    position: absolute;
    user-select: none;
    top: 0;
    left: 0;
    pointer-events: none;
}

.half-desktop{
    width: 274px;
    height: 630px;
    position: absolute;
    user-select: none;
    top: 35px;
    left: 0px;
}

#ad-preview-iframe-container{
    top: 0;
    position: relative;
    left: 0;
    width: 352px;
    height: 706px;
    transform: scale(0.9);
    transform-origin: top;
    &.sticky{
        top: 10px;
        position: sticky;
    }
    &.background-color{
        background:rgba(46, 53, 71, 0.8);
        border-radius: 5px;
        border: 1px solid var(--bb-bg-color-gray);
    }
    .ad-preview-iframe, .preview-iframe, .creative-frame-add{
        border-radius: 0;
        position: relative;
        top: 37px;
        z-index: 2;
        left: 17px;
        filter: none;
        &.no-border-radius{
            border-radius: 0px !important;
            width: 272px !important;
            height: 459px !important;
            transform: inherit !important;
            top: 46px !important;
            left: -14px !important;
        }
    }

    #ad-preview-spinner{
        width: 100%;
        margin-bottom: 0px; 
        margin-top: 0px; 
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.reload-ad{
    text-align: center;
    bottom: -26px;
    position: absolute;
    width: 100%;
    margin: 0;
    &.-left{
        left: 54px;
    }
    span{
        font-family: "CenturyGothic", sans-serif;
    }
}

.zoom-ad{
    text-align: center;
    bottom: -26px;
    position: absolute;
    cursor: pointer;
    z-index: 9;
    width: 100%;
    margin: 0;
    right: 65px;
    span{
        font-family: "CenturyGothic", sans-serif;
    }
}
.email-button{
    font-size: 42px;
    height: 125px;
    padding: 0 !important;
    max-width: 62px !important;
}
.h-125px{
    height: 125px !important;
}

.creative-page-QR{
    margin: 0px;
    margin-top: 30px;
    padding-right: 15px !important;
    padding-left: 15px !important;
    p{
        text-align: left;
    }
    .margin_15_x{
        margin-right: 15px;
    }
}


.disclaimer{
    display: flex;
    text-align: center;
    bottom: -92px;
    position: absolute;
    width: 100%;
    margin: 0;
    padding-left: 22px;
    img{
        margin-right: 20px;
    }
    span{
        font-family: "CenturyGothic", sans-serif;
        letter-spacing: -0.265922px;
        text-align: left;
        strong{
            margin-bottom: 4px;
        }
    }
}