.ReactTable {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    * {
      box-sizing: border-box;
    }
    .rt-table {
      -webkit-box-flex: 1;
      -ms-flex: auto 1;
      flex: auto 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      width: 100%;
      border-collapse: collapse;
      overflow: auto;
    }
    .rt-thead {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &.-headerGroups {
        background: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
      &.-filters {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        input, select {
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 5px 7px;
          font-size: inherit;
          border-radius: 3px;
          font-weight: normal;
          outline: none;
        }
        .rt-th {
          border-right: 1px solid rgba(0, 0, 0, 0.02);
        }
      }
      &.-header {
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
      }
      .rt-tr {
        text-align: center;
      }
      .rt-th, .rt-td {
        padding: 5px 5px;
        line-height: normal;
        position: relative;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: inset 0 0 0 0 transparent;
      }
      .rt-th.-cursor-pointer, .rt-td.-cursor-pointer {
        cursor: pointer;
      }
      .rt-th:last-child, .rt-td:last-child {
        border-right: 0;
      }
      .rt-th:focus {
        outline: none;
      }
      .rt-resizable-header {
        //padding: 6px 24px !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        overflow: visible;
        &:last-child {
          overflow: hidden;
        }
      }
      .rt-resizable-header-content {
        &, & > *{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:after{
          position: absolute;
          right: 8px;
          top: 15px;
          height: 18px;
          width: 18px;
          z-index: 120;
          color: transparent;
          content: '.';
        }
      }
      .rt-header-pivot {
        border-right-color: #f7f7f7;
        &:after, &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #fff;
          border-width: 8px;
          margin-top: -8px;
        }
        &:before {
          border-color: rgba(102, 102, 102, 0);
          border-left-color: #f7f7f7;
          border-width: 10px;
          margin-top: -10px;
        }
      }
    }
    .rt-tbody {
      -webkit-box-flex: 99999;
      -ms-flex: 99999 1 auto;
      flex: 99999 1 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      //-ms-flex-direction: column;
      //flex-direction: column;
      //overflow: unset;
      .rt-tr-group {
        border-bottom: solid 1px rgba(0, 0, 0, 0.05);
        &:last-child {
          border-bottom: 0;
        }
      }
      .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.02);
        &:last-child {
          border-right: 0;
        }
      }
      .rt-expandable {
        cursor: pointer;
        text-overflow: clip;
      }
    }
    .rt-tr-group {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .rt-tr {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
    .rt-th, .rt-td {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
      flex: 1 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 7px 5px;
      overflow: hidden;
      transition: .3s ease;
      transition-property: width, min-width, padding, opacity;
    }
    .rt-th.-hidden, .rt-td.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    }
    .rt-expander {
      display: inline-block;
      position: relative;
      margin: 0;
      color: transparent;
      margin: 0 10px;
      i{
        -webkit-transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
      }
      &.-open i {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    .rt-resizer {
      display: inline-block;
      position: absolute;
      width: 36px;
      top: 0;
      bottom: 0;
      right: -18px;
      cursor: col-resize;
      z-index: 10;
      &:before{
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 15px;
        height: 18px;
        width: 18px;
        color: transparent;
        content: '.';
        background-size: 18px 18px;
        background-repeat: no-repeat; 
        opacity: 0.87;
      }
    }
    .rt-tfoot {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      
      .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        font-weight: bold;
        &:last-child {
          border-right: 0;
        }
      }
    }
    &.-striped .rt-tr.-odd {
      background: rgba(0, 0, 0, 0.03);
    }
    &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
      background: rgba(0, 0, 0, 0.05);
    }
    .-pagination {
      z-index: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 3px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      border-top: 2px solid rgba(0, 0, 0, 0.1);
      input, select {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none;
      }
      .-btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 3px;
        padding: 6px;
        font-size: 1em;
        color: rgba(0, 0, 0, 0.6);
        background: rgba(0, 0, 0, 0.1);
        transition: all .1s ease;
        cursor: pointer;
        outline: none;
        &[disabled] {
          opacity: .5;
          cursor: default;
        }
        &:not([disabled]):hover {
          background: rgba(0, 0, 0, 0.3);
          color: #fff;
        }
      }
      .-previous, .-next {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
      }
      .-center {
        -webkit-box-flex: 1.5;
        -ms-flex: 1.5;
        flex: 1.5;
        text-align: center;
        margin-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: distribute;
        justify-content: space-around;
      }
      .-pageInfo {
        display: inline-block;
        margin: 3px 10px;
        white-space: nowrap;
      }
      .-pageJump {
        display: inline-block;
        input {
          width: 70px;
          text-align: center;
        }
      }
      .-pageSizeOptions {
        margin: 3px 10px;
      }
    }
    .rt-noData {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: rgba(255, 255, 255, 0.8);
      transition: all .3s ease;
      z-index: 1;
      pointer-events: none;
      padding: 20px;
      color: rgba(0, 0, 0, 0.5);
    }
    .-loading {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      transition: all .3s ease;
      z-index: -1;
      opacity: 0;
      pointer-events: none;
      > div {
        position: absolute;
        display: block;
        text-align: center;
        width: 100%;
        top: 50%;
        left: 0;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        -webkit-transform: translateY(-52%);
        transform: translateY(-52%);
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
      &.-active {
        opacity: 1;
        z-index: 200;
        pointer-events: all;
        > div {
          -webkit-transform: translateY(50%);
          transform: translateY(50%);
        }
      }
    }
    .rt-resizing {
      .rt-th, .rt-td {
        transition: none !important;
        cursor: col-resize;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
    .rt-th.-sort-asc.number-col div:first-child, .rt-th.-sort-desc.number-col div:first-child{
      margin-right: 20px;
    }
  }
.large-height .rt-tr > div{
  min-height: 64px;
  display: flex;
  align-items: center;
}
.subtable-header{
  height: 25px;
  min-height: 25px !important;
  &-row{ pointer-events: none; }
}
.rt-th, .large-height .rt-tr > div {
  &.text-left{ justify-content: start !important; }
  &.text-right{ justify-content: end !important; }
}
.rt-td .bb-button{ min-width: auto; display: flex; align-items: center;}
.bb-table-tooltip{
  position: relative;
  z-index: 999;
}