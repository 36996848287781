
.bb-base{
    width:100%;
    height:100%;
    position:relative;
    overflow:hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    flex-direction:column;
    
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    h1{
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
        text-transform:uppercase;
        text-align:center;
        font-size:170px;
        font-weight: bold;
        display:block;
        margin:0;
        color:$bb-secondary-color;
        position:relative;
        z-index:0;
        animation: colors .4s ease-in-out forwards;
        animation-delay: 1.7s;
        &:before{
            content:"U";
            position:absolute;
            top:-9%;
            right:42%;
            transform:rotate(180deg);
            font-size:70px;
            color:$bb-secondary-color;
            z-index:-1;
            text-align:center;
            animation:lock .2s ease-in-out forwards;
            animation-delay: 1.5s;
        }
    }
    h2{
        color:$bb-secondary-color;
        font-weight: bold;
        margin:0;
        text-transform:uppercase;
        text-align:center;
        animation: colors .4s ease-in-out forwards;
        animation-delay: 2s;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    }
    h5{
        color:$bb-secondary-color;
        font-weight: bold;
        margin:0;
        text-align:center;
        opacity:0;
        animation: show 2s ease-in-out forwards;
        color:$bb-theme-color;
        animation-delay: 3s;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    }
    
}

@keyframes lock{
50%{top:-4%;}
100%{top:-6%;}
}

@keyframes colors{
50%{transform:scale(1.1);}
100%{color:$bb-theme-color;}
}

@keyframes show{
100%{opacity:1;}
}