

$bb-primary-font-v2: "Ubuntu";

.timezone-select{
    width: 300px;
}
.maxW{
    max-width: 1284px;
}
.direction-button{
    max-width: 1284px;
    div{
        cursor: pointer;
        text-align: center;
        padding: 11px;
        font-size: 13px;
        color: var(--bb-primary-text-3);
        letter-spacing: 0.01em;
    &.selected-page{
        font-weight: bold;
        letter-spacing: -0.02em;
        color: var(--bb-primary-blue-1);
        cursor: auto;
        border-bottom:2px solid var(--bb-primary-blue-1);
    }
    }

    .bb-eoc-button{
        position: absolute;
        right: 0;
    }
}
.report-container{

    hr{
        border-top: 1px solid var(--bb-primary-background-0);
        &.dashed{
            margin-top: 8px;
            margin-bottom: 12px;
            border-top: 1px dashed var(--bb-primary-background-0);
        }
    }
    max-width: 1284px;
    // background-color: var(--bb-primary-background-2);
    .bg_report{
        background-color: var(--bb-primary-background-2);
        padding: 24px !important;

    }
    .video-chart{
        margin: 12px !important;
    }
    .min-width{
        min-width: 500px;
    }
    .chart-container{
        max-width: 900px;
        min-width: 400px;
    }
    .sparkline-cards{
        width: 180px;
        height: 240px;
        position: relative;
        background: var(--bb-primary-background-2);
        border: 1px solid var(--bb-primary-text-4);
        box-sizing: border-box;
        border-radius: 10px;
        margin: 12px;
        padding: 12px;
        
    }
    
    .emission-card{
        min-height: 240px;
        height: auto !important;
        .summary-card-value {
            font-size: 17px !important;
            .main { font-size: 27px; }
        }
        &.creative-consumer {
            border: 1px dashed var(--bb-primary-pink-2);
            .phone-icon {
                position: absolute;
                right: 24px;
                bottom: 12px;
            }
        }
    }
    .summary-card{
        width: 180px;
        height: 240px;
        position: relative;
        background: var(--bb-primary-background-2);
        border: 1px solid var(--bb-primary-text-4);
        box-sizing: border-box;
        border-radius: 10px;
        margin: 12px;
        padding: 12px;
        margin-left: 0;
        margin-right: 17px;
        &.opt{
            height: 108px;
            &.imp-cont{
                width: 280px;
            }
        }
        &.attention-score{
            border-color: #A01A7D !important;
            .summary-icon{
                color: #A01A7D !important;
                &.bb-color_disabled_imp{
                    color: var(--bb-primary-background-05) !important;
                }
            }
        }
        .creative-image{
            overflow: hidden;
            display: flex;
            align-items: center;
            margin: 0 auto;
            text-transform: capitalize;
            .ads-images{
                width: 100%;
            }
            .creative-index{
                padding-left: 10px;
                position: absolute;
                background: white;
                bottom: 20px;
                left: 13px;
                padding-right: 10px;
                border-radius: 19px;
                font-weight: bold;
                font-size: 12px;
                padding-top: 3px;
                padding-bottom: 3px;
                text-align: center;
                color: black;
            }
        }
        .header-font{
            color: var(--bb-primary-text-2);
        }
        .summary-card-line-0{
            .summary-card-value{
                font-weight: bold;
                font-size: 27px;
                line-height: 34px;
                font-family: $bb-primary-font-v2;
                color: var(--bb-primary-text-1);
                &.font_17{
                    font-size: 17px;
                }
            }
            .summary-card-label{
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 1%;
                font-family: $bb-primary-font-v2;
                color: var(--bb-primary-text-3);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 151px;
            }

        }
        .summary-card-line-1{
            .summary-card-value{
                font-size: 17px;
                line-height: 28px;
                font-family: $bb-primary-font-v2;
                color: var(--bb-primary-text-2);
            }
            .summary-card-label{
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 1%;
                font-family: $bb-primary-font-v2;
                color: var(--bb-primary-text-3);
            }
        }
        .summary-card-line-2{
            .summary-card-value{
                font-size: 17px;
                line-height: 28px;
                font-family: $bb-primary-font-v2;
                color: var(--bb-primary-text-2);
            }
            .summary-card-label{
                font-size: 11px;
                line-height: 18px;
                letter-spacing: 1%;
                font-family: $bb-primary-font-v2;
                color: var(--bb-primary-text-3);
            }
        }
        hr{
            margin-top: 12px;
            margin-bottom: 7px;
            border-top: 1px solid var(--bb-primary-background-05);
        }
        .summary-icon{
            position: absolute;
            bottom: 6px;
            right: 8px;
            font-size: 40px;
            width: 44px;
            height: 44px;
            color: var(--bb-primary-background-05);
            text-align:center;
        }
    }
    .options-card-container{
        font-size: 14px;
        .number-creatives{
            color: var(--bb-primary-text-3)
        }
        &.mx-h{
            .slider-image-item{
                height: 225px;
            }
        }
        .slider-image-item{
            width: 204px  !important;
            height: 260px;
            // max-width: 180px;
            // min-width: 240px;
            // position: relative;
        }
    
        .options-card-img-container{
            width: 180px;
            height: 240px;
            overflow: hidden;
            position: relative;
            
        }
    
        .options-card{
            box-sizing: border-box;
            margin: 12px;
            padding: 12px;
            color: black;
            width: 180px;
            height: 240px;
            position: relative;
            background: var(--bb-primary-background-2);
            border: 1px solid var(--bb-primary-text-4);
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            user-select: none;
            &.admin-card{
                height: 209px;
                &.not-selected{
                    opacity: 0.6 !important;
                }
                .info-container{
                    top: 0px !important;
                }
            }
            &:hover{
                filter: drop-shadow(2px 2px 8px rgba(232, 52, 87, 0.15));
            }
            &.-selected{
                border: 1px solid var(--bb-primary-pink-1);
            }
            &.not-selected{
                opacity: 0.4;
                &:hover{
                    opacity: 1;
                }
            }
            hr{
                margin-top: 5px;
                margin-bottom: 6px;
                border-top: 1px solid var(--bb-primary-background-05);
            }
            .creative-image{
                width: 100%;
                height: 69px;
                overflow: hidden;
                align-items: center;
                left: 0;
                top: 0;
                position: absolute;
                text-transform: capitalize;
                background-color: var(--bb-primary-text-4);
                display: flex;
                justify-content: center;
                .ads-images{
                    max-width: 300px;
                    width: 100%;
                    transition: all 1s ease-in-out;
                    align-self: center;
                   /*  top: -50%;
                    position: absolute; */
                }
            }
            .info-container{
                top: 58px;
                position: relative;
                margin-top: 3px;
                .header-name{
                    width: 100%;
                    color: var(--bb-primary-text-3);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }   
                .header-tag{
                    color: var(--bb-primary-text-1);
                }
                .value{
                    padding-left: 2px;
                    color: var(--bb-primary-text-2);
                }
                .label{
                    color: var(--bb-primary-text-3);
                }
                .preview-button{
                    background: transparent;
                    color: var(--bb-theme-color);
                    border: 1px solid var(--bb-theme-color);
                    padding: 6px 10px 5px 10px !important;
                    cursor: pointer;
                    text-align: center;
                    border-radius: 5px;
                    height: calc(1.5em + 0.75rem + 2px);
                    white-space: nowrap;
                    margin-left: 0px;
                    font-size: 10px;
                    &.preview-selected{
                        color: white;
                        border: 1px solid white;
                    }
                   
                }
            }
            .analytics-button{
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                border-radius: 0px;
                height: 20px;
            }
            
        
        }
        input[type="range"] {
            -webkit-appearance: none;
            width: 100%;
            margin-top:10px;
            &.v-hidden{
                display: none;
            }
          }
          
          input[type="range"]::-webkit-slider-runnable-track {
            width: 300px;
            height: 10px;
            background: var(--bb-primary-background-0);
            border: 1px solid var(--bb-primary-background-0);
            border-radius: 3px;
          }
          
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: none;
            height: 6px;
            width: 60%;
            border-radius: 30px;
            background: var(--bb-primary-text-3);
            margin-top:1px;
            margin-left: 1px;
          }
    
    }
    
}

.accordion-arrow{
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    margin-top: 5px;
    cursor: pointer;
    color: var(--bb-secondary-functionality-1);
    &:hover{
        color: var(--bb-secondary-functionality-05);
    }
    i{
        margin-left: 7px;
        transition: 1s all;
        &.rotate{
            transform: rotate(-90deg);      
        }
    }
}
.accordion-content {
max-height: 0;
opacity: 0.1;
transition: all 0.4s ease-in-out;
overflow: hidden;
    &.open {  
        max-height: 100% !important;
        opacity: 1;
        transition: all 0.4s ease-in-out;
    }
}

hr.close{
    height: 0px !important;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    margin-bottom: 0 !important; 
    margin-top: 0 !important;   
}

