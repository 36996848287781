.bb-error-overlay{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bb-image-container{
        display: inline-block;
        background-image: url('~/public/images/balloon.png'); 
        background-size: cover;
        background-position: center;
        width: 40vh;
        height: 40vh;    
    }

    h2{
        font-size: 28px;
        color: var(--bb-text-color-blue);
    }
}