.bb-container-main::-webkit-scrollbar{
    width: 1.25em; /* Total width including `border-width` of scrollbar thumb */
    height: 0;
    box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
    //border-radius: 10px;
    margin-right: 20px;
    
    &-thumb{
        height: 1em;
        border: 0.3em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: var(--bb-bg-color-tertiary-20);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track{
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 1);
        //border-radius: 10px;
        padding: 10px;
    }
}
body::-webkit-scrollbar{
    width: 1.25em; /* Total width including `border-width` of scrollbar thumb */
    height: 0;
    box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
    //border-radius: 10px;
    margin-right: 20px;
    
    &-thumb{
        height: 1em;
        border: 0.3em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: var(--bb-bg-color-tertiary-20);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track{
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 1);
        //border-radius: 10px;
        padding: 10px;
    }
}

.side-nav::-webkit-scrollbar{
    display:none;
}

textarea::-webkit-scrollbar  {
    width: 8px; 
    height: 5px;
    box-shadow: inset 0 0 50px var(--bb-bg-color-tertiary);
    border-radius: 5px;       
    &-thumb{
        height: 20px;
        border: 2px solid rgba(0, 0, 0, 0); 
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: $bb-theme-color;
        border-radius: 5px;
    }

    &-track{
        border-radius: 5px;
        padding: 5px;
    } 
    
}
  

.bb-all-table > .rt-table::-webkit-scrollbar {
    width: 1.25em; /* Total width including `border-width` of scrollbar thumb */
    height: 16px;
    box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
    // border-radius: 10px;
    margin-right: 20px;
    
    &-thumb{
        height: 1em;
        border: 0.3em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: var(--bb-bg-color-tertiary-20);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track{
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
        //border-radius: 10px;
        padding: 10px;
    }
}
