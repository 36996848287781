.rdrCalendarWrapper{
    color: #000000;
    font-size: 12px;
    ;
  }
  .date-range-container{
    min-width: 400px;
    max-width: 500px;
    position: absolute;
    right: 0;
    overflow: hidden;
    border-radius: 7px;
    border: 1px solid #D9D9D9; 
    box-shadow: 2px 2px 8px rgba(32, 33, 36, 0.03);

    &, button{font-family: "CenturyGothic", sans-serif;}


    .bg-whitesmoke{
      background:whitesmoke;
    }
    .border-radius-top{
      border-radius: 10px 10px 0px 0px;
    }
    .border-radius-bottom{
      border-radius: 0px 0px 10px 10px;
    }
  }
  
  .form-row  .date-range-container{
    right: unset;
    margin-top: 0.5rem;
  }
  
  .date-range-container-invoice{
    background: whitesmoke;
    width: 100%;
    max-width: 296px !important;
    // position: absolute;
    // right: 0;
    border-radius: 10px;
    overflow: hidden;

    .rdrMonthAndYearWrapper{
      background: whitesmoke;
      border-radius: 0px 0px 10px 10px;
    }
    .bg-whitesmoke{
      background:whitesmoke;
    }
    .border-radius-top{
      border-radius: 10px 10px 0px 0px;
    }
    .border-radius-bottom{
      border-radius: 0px 0px 10px 10px;
    }
    select{
      font-size: 16px;
    }
    .rdrNextPrevButton{ &.rdrPprevButton , &.rdrNextButton{ &.disabled{visibility: hidden;}}}
  }
  
  .rdrDateDisplay{
    background-color: whitesmoke;
    padding: 0.833em;
  }
  
  .rdrDateDisplayItem{
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
    input{
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: #849095;
    }
  }
  
  .rdrDateDisplayItemActive{
    border-color: currentColor;
  }
  
  .rdrDateDisplayItemActive{
    input{
      color: #7d888d
    }
  }
  
  .rdrMonthAndYearWrapper {
    align-items: center;
    // height: 60px;
    // padding-top: 10px;
  }
  
  .rdrMonthAndYearPickers{
    font-weight: 600;
    select{
      appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 10px 30px 10px 10px;
      border-radius: 4px;
      outline: 0;
      color: #3e484f;
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      text-align: center;
      &:hover{
        background-color: rgba(0,0,0,0.07);
      }
    }
  }
  
  .rdrMonthPicker, .rdrYearPicker{
    margin: 0 5px
  }
  
  
  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 0.833em;
    padding: 0;
    border: 0;
    border-radius: 5px;
    background: #EFF2F7;
    &:hover{
      background: #E1E7F0;
    }
    i {
      display: block;
      width: 0;
      height: 0;
      padding: 0;
      text-align: center;
      border-style: solid;
      margin: auto;
      transform: translate(-3px, 0px);
    }
  }
  
  .rdrPprevButton {
    i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent rgb(52, 73, 94) transparent transparent;
      transform: translate(-3px, 0px);
    }
  }
  
  .rdrNextButton {
    i {
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent rgb(52, 73, 94);
      transform: translate(3px, 0px);
    }
  }
  
  
  .rdrWeekDays {
    padding: 0 0.833em;
  }
  
  .rdrMonth{
    padding: 0 0.833em 1.666em 0.833em;
    .rdrWeekDays {
      padding: 0;
    }
  }  
  
  .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
    display: none;
  }  
  
  .rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: rgb(132, 144, 149);
  }
  
  .rdrDay {
    background: transparent;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429;
    &:focus {
      outline: 0;
    }
  }
  
  .rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rdrDayToday .rdrDayNumber span{
    font-weight: 500;
    &:after{
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background: #fff !important;
    }
  }
  
  .rdrDayToday:not(.rdrDayPassive) {
    .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected{
      & ~ .rdrDayNumber span:after{
        background: #fff;
      }
    }
  }
  
  .rdrDay:not(.rdrDayPassive){
    .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected{
        & ~ .rdrDayNumber{
          span{
            color: rgba(255, 255, 255, 0.85);
          }
        }
    }
  }
  
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: currentColor;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
  }
  
  .rdrSelected{
    left: 2px;
    right: 2px;
  }
  
  .rdrInRange{}
  
  .rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }
  
  .rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }
  
  .rdrSelected{
    border-radius: 1.042em;
  }
  
  .rdrDayStartOfMonth, .rdrDayStartOfWeek{
    .rdrInRange, .rdrEndEdge{
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      left: 2px;
    }
  }
  
  .rdrDayEndOfMonth, .rdrDayEndOfWeek{
    .rdrInRange,  .rdrStartEdge{
      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      right: 2px;
    }
  }
  
  .rdrDayStartOfMonth, .rdrDayStartOfWeek{
    .rdrDayInPreview, .rdrDayEndPreview{
      border-top-left-radius: 1.333em;
      border-bottom-left-radius: 1.333em;
      border-left-width: 1px;
      left: 0px;
    }
  }
  
  .rdrDayEndOfMonth, .rdrDayEndOfWeek{
    .rdrDayInPreview, .rdrDayStartPreview{
     border-top-right-radius: 1.333em;
     border-bottom-right-radius: 1.333em;
     border-right-width: 1px;
     right: 0px;
   }
  }
  
  
  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid currentColor;
    z-index: 1;
  }
  
  
  .rdrDayStartPreview{
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    left: 0px;
  }
  
  .rdrDayInPreview{
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  
  .rdrDayEndPreview{
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    right: 2px;
    right: 0px;
  }
  .rdrDateDisplayWrapper{
    display: none !important;
  }
  
  .rdrDefinedRangesWrapper{
    font-size: 12px;
    width: 168px;
    border-right: 2px solid var(--bb-theme-color) !important;
    background: #fff;
    .rdrStaticRangeSelected{
      color: var(--bb-theme-color) !important;
      font-weight: 900;
    }
  }
  
  .rdrStaticRange{
    border: 0;
    cursor: pointer;
    display: block;
    outline: 0;
    border-bottom: 1px solid #eff2f7;
    // padding: 0;
    background: #fff;
    &:nth-child(2){
      display: none;
    }
    &:hover, &:focus{
      .rdrStaticRangeLabel{
        background: ghostwhite;
      }
    }
  }
  
  
  .rdrStaticRangeLabel{
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 10px 20px;
    text-align: left;
  }
  
  .rdrInputRanges{
    padding: 10px 0;
  }
  
  .rdrInputRange:nth-child(2){
    display: none;
  }
  .rdrInputRange{
    align-items: center;
    padding: 5px 20px;
    color: #000000;
    font-size: 10px;

  }
  
  .rdrInputRangeInput{
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    text-align: center;
    border: solid 1px #333;
    margin-right: 10px;
    color: rgb(108, 118, 122);
    &:focus, &:hover{
      border-color: var(--bb-theme-color)  !important;
      outline: 0;
      color: #333;
    }
  }
  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
    content: '';
    border: 1px solid currentColor;
    border-radius: 1.333em;
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0px;
    right: 0px;
    background:var(--bb-theme-color)  !important;
    color: var(--bb-text-color-vendor);
  }
  
  .rdrDayPassive{
    pointer-events: none;
    .rdrDayNumber span{
      color: #d5dce0;
    }
    .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
      display: none;
    }
  }
  
  .rdrDayDisabled {
    background-color: whitesmoke;
    .rdrDayNumber span{
      color: #aeb9bf;
    }
    .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
      filter: grayscale(100%) opacity(60%);
    }
  }
  
  .rdrMonthName{
    text-align: left;
    font-weight: 600;
    color: #849095;
    padding-left: 10px;
    display: none;
  }
  .rdrDateRangePickerWrapper{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    user-select: none;
  }

  .rdrDateRangeWrapper{
    width: 69%;
  }
  .rdrDefinedRangesWrapper{
    width: 31%;
  }
  
  @media screen and (max-width: 490px) {
    .rdrDateRangeWrapper{
      width: 100%;
    }
    .rdrDefinedRangesWrapper{
      width: 100%;
    }  
  }
  .date-picker-bb-button{
    border-radius: 5px 0px 0px 5px;
    min-width: 20px;
    background: white;
    color: #CCCCCC;
    border: 1px solid #CED4DA;
    border-right: 0;
    &:hover{ background-color: white !important; filter: none !important;}
  }

  .date-picker-input{
    max-width: 250px;
    min-width: 230px;
    color: black;
    background-color: var(--bb-color-white) !important;
    border-radius: 0 5px 5px 0;
    border: 1px solid #CED4DA;
    border-left: 0;
    input{
      outline: none;
      border: none;
      box-shadow: none !important;
      cursor:auto;
      height: 36px;
    }
   

}
  .date-picker{
    position: sticky;
    top: 0px;
    background: #1c2135;
    z-index: 98;
    font-family: "CenturyGothic", sans-serif;
    &.--light{
      background: transparent;
      label{
        font-size: 13px;
        line-height: 21px;
        letter-spacing: 1%;
        color: var(--bb-primary-text-3);
      }
      .rdrDefinedRangesWrapper{
        border-right: 2px solid var(--bb-primary-background-0) !important;
      }
      .rdrStaticRangeSelected {
        color: var(--bb-secondary-functionality-1) !important;

      }
      .date-picker-bb-button{
        border-radius: 5px 0px 0px 5px;
        width: 29px;
        text-align: center;
        height: 30px;
        margin: 0;
        font-size: 13px;
        padding: 0px !important;
        border-right: 0;
        height: 100%;
        &:hover{
          background: white;
        }
        &:active, &__active{
          filter: none;
        }
        &:disabled{
          background: var(--bb-primary-text-3);
        }
      }
      .date-picker-input{
        max-width: 200px;
        padding: 4px 12px;
        border-radius: 0px 5px 5px 0px;
        &:hover{ background-color: white !important; filter: none !important;}
      }
      input{
        padding: 0px;
        font-size: 13px;
        line-height: 21px;
        height: 100%;
      }
    }
    .campaign-date-picker{
      &.date-picker-input { padding: 0 ;}
      input{
        font-size: 16px;
        max-width: 125px;
        padding: 0 12px;
      }
    }

    &-overlay{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0px;    
      background: rgba(0,0,0,0.1);
    }

    &-overlay-full{
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      margin-top: 0;
      left: 0px;    
      background: rgba(0,0,0,0.1);
    }

  }
  .light-date-picker, .dip-date-picker {
    .date-range-container, .date-range-container button, &.date-picker{ font-family: "Ubuntu"; }
  }
  .dip-date-picker .date-range-container{
    right: 0 !important;
  }