.fee{
    .header-container{
        border: 1px solid var(--bb-primary-text-4);
        box-sizing: border-box;
        border-radius: 10px;
        padding: 20px;
    }
    .fake-input{
        &.small{
            width: 150px !important;
        }
        &.xsmall{
            width: 93px !important;
        }
        &.xxsmall{
            width: 53px !important;
        }
        &.big{
            width: 309px !important;
        }
       
    }
    .fee-grey{
        background-color: var(--bb-primary-text-4);
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        border: 1px solid var(--bb-primary-text-4);
        box-sizing: border-box;
        border-radius: 10px;
        padding: 20px;

    }
    .border{
        border: 1px solid var(--bb-primary-text-4);
        box-sizing: border-box;
        border-radius: 10px;
        padding: 20px;
    }
}