// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
 }

 // Custom devices
@mixin cwd($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
 }

 /* Max-width */
  // Custom devices
@mixin cwd-max($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
 }

 @mixin cwd-min-max($min, $max) {
    @media (min-width: $min+'px') and (max-width: $max+'px') {
        @content;
    }
 }