

.slick-list{
    top: 20px;
}
.slick-slider{
    // padding: 25px;
    min-height: 416px;
    .slick-track{
        height: 480px;
    }
    .slick-prev{
        z-index: 999999;
        left: 25px;
        &:before{
            font-size: 29px;
            color:rgba(0, 0, 0, 0.5);
        }
    }
    .slick-next{
        right: 25px;
        &:before{
            font-size: 29px;
            color:rgba(0, 0, 0, 0.5);
        }
    }   
    .slick-slide{
        //transform: translate(0px, -133px);
        display: flex;
        justify-content: center;
    }
    .slide-container{
        // max-height: 570px;
        &.slide {
            transform: scale(0.4);
            transition: transform 100ms;
            opacity: 0.5;
            transform-origin: top;
            top: 70px;
            position: relative;
            overflow: visible;
        }
        .preview-iframe {
            border-radius: 0 0 0px 0px !important;
            position: relative !important;
            top: 53px !important;
            z-index: 2 !important;
            left: 17px !important;
            -webkit-filter: none !important;
            filter: none !important;
            width: 318px !important;
            height: 622px !important;
            z-index: 999999999 !important;
        }
        .iframe-desktop-skin{
            height: 1020px !important;
            width: 1514px !important;
            transform: scale(0.5) !important;    
            z-index: 999999999 !important;
            border-radius: 18px !important;
    
        }
        &.activeSlide{
            transform: scale(0.57);
            transform-origin: top;
            transition: transform 500ms;
            left: 10px;
            position: relative;
            top:0;
        }
        .iframe-desktop-image{
            max-width: 788px !important;
            height: 545px !important;
            transform: scale(1) !important;
        }
        .slideWrapper {
            display: flex;
            justify-content: center;
           //top: 33px;
            
            &.desktop-view{
                //transform: translate(0px, 113px);
              //  top: 104px;
            }
            .format-image{
                width: 317px !important;
                height: 622px !important;
                top: 53px !important;
                position: relative;
                left: 17px !important;
                opacity: 1;
                // border-radius: 0 0 40px 38px !important;
                z-index: 999999999999999999999999999999;
                &.desktop-view{
                    height: 512px !important;
                    width: 758px !important;
                    top: 16px !important;
                    position: absolute !important;
                    border-radius: 6px !important;
                    left: 16px !important;
                }
                &.active{
                    opacity: 0;
                    transition: all 1s ease-in;
                    pointer-events: none;
                    position: absolute !important;
                }
            }
        }
        .format-detail{
            position: relative;
            top: 92px;
            left: 18px;
            h3{
                width: 100%;
                text-align: center;
                color: var(--bb-color-black) !important;
                font-weight: 700;
            }
            .device-icons{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 8px 12px;
                background: var(--bb-bg-color-tertiary-20);;
                border-radius: 8px;
                i{
                    font-size: 24px;
                    line-height: 25px;
                    &::before{
                        color: var(--bb-bg-color-gray);
                    }
                    &.--active{
                        &::before{
                            color: var(--bb-color-black) !important;
                            font-weight: 700;
                        }
                       
                    }
                }
            }
        }

    }
      
}

 
.carousel-container{
      .kpi-select{
        right: 121px;
        position: relative;
      }
}
.formats-buttons{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 12px;
    background: var(--bb-bg-color-tertiary-20);;
    border-radius: 8px;
    p{
        margin: 4px 15px;
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 25px;
        color: var(--bb-bg-color-gray);
        &.active{
            color: var(--bb-color-black) !important;
            font-weight: 700;
        }
    }
    
}
.mars-phone{
    height: 706px !important;
}
.green-tag{
    background: var(--bb-green-100);
    color: var(--bb-green-800);
    padding: 3px 14px;
    border-radius: 5px;
    font-weight: bolder;
}