@media only screen and (max-width: 476px) {
  .bb-button{
      margin-top: 10px;
  }
}

@media only screen and (max-width: 1076px) {
  .bb-button{
      margin-top: 5px;
  }
} 
.bb-button {
    background: $bb-theme-color;
    color: var(--bb-text-color-vendor);
    border: none; 
    padding: 5px 10px 5px 10px !important;
    cursor: pointer;
    min-width: 100px;
    text-align: center;
    border-radius:5px;
    height: calc(1.5em + .75rem + 2px);
    white-space: nowrap;
   
    &.bb-button-info{
      width: 165px !important;
    }

    &:hover:not(.bb-button-info){
        @include filter(brightness(1.2) grayscale(0.5) opacity(0.9));
    }

    &:disabled, &-disabled {
      background: $bb-disabled-color !important;
        color: var(--bb-text-color-white);
    }
    &-disabled{ border: 1px solid $bb-disabled-color !important; }
    &:focus {
      outline: none;
    }

    &, &-outline{
      white-space: nowrap;
    }
    &-outline{
      background: transparent;
      color: $bb-theme-color;
      border: 1px solid $bb-theme-color;
      height: 33px;
      padding: 5px 10px !important;
      border-radius: 5px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      i{ font-size: 16px; }

      &-blue{
        color: $bb-blue-400;
        border: 1px solid $bb-blue-400;
      }
    }
    
    &-tertiary, &.bb-back-button{
      background-color: $bb-bg-tertiary-color !important;
      border: 1px solid $bb-bg-tertiary-color-20;

      &-20{
        background-color: $bb-bg-tertiary-color-20 !important;
        border: 1px solid transparent;
      }
      &, &-20{
        min-width: min-content;
        height: 33px;
        display: inline-flex;
        align-items: center;
        
        i{ font-size: 16px; }
        &:hover{
          background-color: #1c2135 !important;
          filter: none !important;
          border: 1px solid $bb-bg-tertiary-color-20;
        }
      }
    }

    &-kot{
      white-space: nowrap;
      background-color: rgba(42, 49, 67, 0.50);
      border: 1px solid $bb-bg-tertiary-color-20;
      display: inline-flex;
      align-items: center;
      padding: 5px 10px 5px 10px !important;
      text-align: center;
      border-radius: 5px;
      height: 33px;
      &.error{
        border-color: rgba(235, 87, 87, 0.40) !important;
      }
    }
    &.bb-back-button{
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0 30px !important;
    }
  }

  .bb-big-button{
    max-width: 500px !important;
  }
  
  .bb-button.disabled{
    background: $bb-disabled-color;
  }
  
  .bb-button.download{
    min-width: 0px;
  }

  .bb-creative-buttons {   
    align-self: center;
    width: 100%;
  }

  .ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    &:hover{
      @include filter(brightness(1.2) grayscale(0.5) opacity(0.9));
    }
  }
  
  .bb-input_blue-700{
    input {
      &:checked + label {
        &:after {
          content: "✓";
          background-color: var(--bb-blue-700) !important;
          
        }
      }
    }
}


  .ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }
  .ripple:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
  } 

  
.radio-container {
  div {
    max-width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;

    input {
      display: none;

      &:checked + label {
        background-color: $bb-theme-color;
        opacity: 1;
        color: var(--bb-text-color-vendor);
        &:after {
          content: "✓";
          background-color: $bb-theme-color;
          color: var(--bb-text-color-vendor);
          // z-index: 999;
          position: absolute;
          right: -5px;
          border-radius: 50%;
          border: 2px double $bb-text-color-white;
          font-size: 10px;
          text-align: center;
          top: -5px;
          height: 15px;
          font-weight: bold;
          width: 15px;
          line-height: 12px;
        }
      }
    }
    .radio_label_123{
      width: 123px !important;
    }
    .radio_label_125{
      min-width: 125px !important;
      white-space: nowrap;
    }
    .radio_label_env{
      width: 143px !important;
    }
    label {
      position: relative;
      color: var(--bb-text-color-vendor);
      background-color: $bb-theme-color;
      opacity: 0.5;
      text-align: center;
      display: block;
      cursor: pointer;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 5px 10px 5px 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 50px;
      border-radius: 5px;
      &:hover {
        -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
        -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
        filter: brightness(1.2) grayscale(0.5) opacity(0.9);
      }

      img {
        width: 100px;
        align-self: center;
      }
    }
  }
}


.customised-icon, .card-dercarbonised-icon {
  background: var(--bb-theme-color-secondary);
  min-width: 24px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  border-radius: 5px;
}
.card-dercarbonised-icon{
  background: var(--bb-green-600);
}
.decarbonise-tag{
  border-radius: 5px;
  padding: 3.5px 8px;
  margin-left: 11px;
  max-height: 24px;
  white-space: nowrap;
  color: white;
  .decarbonise-eye-icon{ margin-bottom: 1px; margin-right: 4px; }
}
.style-none{
  &, &:hover{
    background: transparent !important;
    border: 0;
    padding: 0 !important;
    margin: 0 !important;
  }
  i{ font-size: 14px;}
}
.mx{
  &-250 { max-width: 250px !important; }
  &-280 { max-width: 280px !important; }
  &-300 { max-width: 300px !important; }
  &-500 { max-width: 500px !important; }
  &-700 {
      max-width: 700px !important;
  }
  &-1000 {
      max-width: 1000px !important;
  }
}