@mixin orientation($direction) { 

    $orientation-landscape: "(orientation:landscape)"; 
    $orientation-portrait: "(orientation:portrait)";
  
    @if $direction == landscape {
      @media #{$orientation-landscape} { @content; } 
    }
    @if $direction == portrait {
      @media #{$orientation-portrait} { @content; } 
    }
  } 

  @font-face {
    font-family: 'BLOKK';
    src: url('./BLOKKNeue-Regular/BLOKKNeue-Regular.eot');
    src: url('./BLOKKNeue-Regular/BLOKKNeue-Regular.eot?#iefix') format('embedded-opentype'), url('./BLOKKNeue-Regular/BLOKKNeue-Regular.woff') format('woff'), url('./BLOKKNeue-Regular/BLOKKNeue-Regular.ttf') format('truetype'), url('./BLOKKNeue-Regular/BLOKKNeue-Regular.svg#BLOKKRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  } 

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'BLOKK';
        src: url('./BLOKKNeue-Regular/BLOKKNeue-Regular.svg') format('svg');
    }
}
 
.bm-creative-preview{ 

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    font-family: "BLOKK", serif;
    letter-spacing: -0.1em;
    line-height: 1.2em;
    word-break: break-all;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    //border-radius: 0px 12px 40px 30px;
    background: #fff;

    .creative {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &-header{
        background: #1b2134;
        text-align: center;
        img{
            max-width: 100%;
            user-select: none;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            width: 130px;
            padding: 20px 0;
            display: unset !important;
        }
    }
    &-ad-content{        
        .placeholder {
            background: lightgrey;
            max-width: 150px;
            min-width: 150px;            
            margin-right: 10px;
            height: 150px;
            &:after{
                content: "";
                display: block;
                padding-bottom: 100%;                
            }
        }
        @media only screen and (max-height: 480px) and (orientation: landscape){
            .placeholder{
                height: 100px;
            }       
        }
    }

    p {
        color: lightgray;
        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        margin-bottom: 0px !important;
    }

    h2 {
        color: gray;
        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    .bm-ad{
        font-family: 'CenturyGothic';
        letter-spacing: normal;
        clear: both;
        margin: 10px 0px; 
        padding: 0 !important;
        overflow: hidden;
        &-default{
            width: 300px;
            height: 300px;  
            margin: 0 auto;
            align-items: center;          
        }

        &-desktop{
            font-family: 'CenturyGothic';
        letter-spacing: normal;
        clear: both;
        //margin: 10px 0px; 
        padding: 0 !important;
        display: flex;
        justify-content: center;

            &-default{
                width: 300px;
                height: 300px;  
                margin: 0 auto;
                align-items: center;          
            }

            #bm-creative{
                display: flex;
                justify-content: center;
                //width: 100%;
            }
        }
    }

    &-scroller{
        z-index: 2; position: absolute; top:0; 
        width: 100%; 
        //height: 848px; 
        background: transparent;
        overflow: unset !important;
    }

    &-scrollo{
        overflow: inherit !important;
    }
} 


.bm-creative-preview::-webkit-scrollbar {
    width: 7px; /* Total width including `border-width` of scrollbar thumb */
    height: 7px;
    box-shadow: inset 0 0 100px #2E3547;
    // border-radius: 10px;
    margin-right: 20px;
    
    &-thumb{
        height: 1em;
        border: 0.1em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: rgba(202, 203, 211, 0.20);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track{
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
        //border-radius: 10px;
        padding: 10px;
    }
}

.bm-ad{
    font-family: 'CenturyGothic';
    letter-spacing: normal;
    clear: both;
    margin: 10px 0px; 
    padding: 0 !important;
    overflow: hidden;
    &-default{
        width: 300px;
        height: 300px;  
        margin: 0 auto;
        align-items: center;          
    }

    &-desktop{
        font-family: 'CenturyGothic';
    letter-spacing: normal;
    clear: both;
    //margin: 10px 0px; 
    padding: 0 !important;
    display: flex;
    justify-content: center;

        &-default{
            width: 300px;
            height: 300px;  
            margin: 0 auto;
            align-items: center;          
        }

        #bm-creative{
            display: flex;
            justify-content: center;
            //width: 100%;
        }
    }
}

#bm-creative-container{
    background: transparent;
}

#bm-preview-iframe-container{
    position: relative;
    z-index: 2;   
}

.noselect{
    user-select: none;
    background: white;
}
#bm-creative-preview{
    -webkit-filter: drop-shadow(0px 5px 10px black);
    filter: drop-shadow(0px 5px 10px black);
    width: 306px;
    height: 633px;
    border-radius: 0;
    position: relative;
    top: 37px;
    left: 17px;
    -webkit-filter: none;
    filter: none;
    &.xl{
        width: 319px;
    }
}

.overflow-h{
    overflow: hidden !important;
}

.bm-desktop-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 978px;
    margin: 0 auto;
}


.iframe-desktop-primo{
    height: 1080px !important;
    width: 1920px !important;
    transform: scale(0.30);
    transform-origin: top left;
    position: absolute !important;
    top: 17px !important;
    left: 17px !important;
    border-radius: 0px !important; 
  }
  .scale-down{
    // transform: scale(0.9); left: -10px; top: 12.5px;
    display: flex;
    justify-content: center;
  }

  .primo-fullscreen-container{
    display: inline;
    position: fixed !important;
    overflow: hidden !important;
    margin: 0px !important;
    left: 21px;
    top: 54px;
    cursor: pointer;
    -webkit-user-select: none !important;
    user-select: none !important;
    -webkit-tap-highlight-color: transparent;
    width: 307px;
    height: 633px;
    touch-action: pan-y;
    border-radius: 0 0 37.4px 37.4px;
    z-index: 2;
    .fullscreen-child {  
        position: absolute !important;
        overflow: hidden !important;
        margin: 0px !important;
        display: inline !important;
        top: 86px !important;
        left: 1px !important;
    }
  }
  .close-button-container{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
    position: absolute; 
    z-index: 2147483002; 
    box-sizing: border-box; 
    padding: 10px; 
    cursor: pointer; 
    width: 45px;
    height: 45px; 
    display: block; 
    top: 54px; 
    right: 23px; 
    opacity: 1;
    .close-button{
        color: rgb(255, 255, 255); 
        border: 2px solid rgb(255, 255, 255); 
        border-radius: 50%; 
        background: rgba(33, 33, 33, 0.5); 
        position: relative; 
        text-align: center; 
        width: 100%; 
        height: 100%; 
        padding: 8%; 
        box-sizing: border-box; 
        font-family: Arial; 
        font-size: 18px; 
        line-height: 18px;
    }
  }