@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/CenturyGothic.ttf') format('truetype');
}

$bb-theme-color: #E83457; //var(--bb-theme-color); 
$bb-budster-background-color: var(--bb-budster-background-color);
$bb-budster-border-color: var(--bb-budster-border-color);
$bb-bg-secondary-color: #F5F5F5;
$bb-text-color-white: #FFF;

@mixin horizontal-borders {
    border-right: 2px solid $bb-budster-border-color;
    border-left: 2px solid $bb-budster-border-color;  
  }

  
#chatlio-widget{
    *{
        font-family: 'CenturyGothic' !important;
    }
    .chatlio-widget-overlay{
        background: $bb-bg-secondary-color !important;
        height: 436px;
        top: 141px;
        @include horizontal-borders;
    }
    .chatlio-confirm-conversation-end{
        padding: 130px 0;
    }
    .chatlio-message-container{
        overflow: auto !important;
        @include horizontal-borders;

    &::-webkit-scrollbar {
        width: 10px; /* Total width including `border-width` of scrollbar thumb */
        height: 0;
        box-shadow: inset 0 0 50px var(--bb-bg-color-tertiary);
        border-radius: 0px;       
        &-thumb{
            height: 10px;
            border: 2px solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` does the trick */
            background-clip: padding-box;
            -webkit-border-radius: 0.5;
            background-color: $bb-theme-color;
            border-radius: 5px;
        }
    
        &-track{
            border-radius: 5px;
            padding: 5px;
        }
    }
}
    .chatlio-widget-body {
        bottom: 113px;
        right: 21px;
        border-radius: 20px 20px 0px 20px !important;
        background-color: $bb-bg-secondary-color !important;
        border: 2px solid $bb-budster-background-color;
        overflow: unset !important;
    }

    .big-header{
        height: 70px;
        background: $bb-budster-background-color;
        color:$bb-text-color-white;
        border-radius: 20px 20px 0px 0px !important;
        img{
            width: 60px;
            position: relative;
            top: -20px;
            left: 10px;
        }
        #hi{
            font-size: 22px;
            margin-left: 15px;
        }
        #chat-welcome-text{
            font-size: 22px;
        }
    }
    
    #chatlio-message-textarea{
        @include horizontal-borders;
        border-radius: 0 0 0 20px;
    }

    .chatlio-chat-header{
        background-color: $bb-bg-secondary-color !important;
        @include horizontal-borders;
        border-radius: 0px;
        &.big-header{
            border-bottom: 4px solid $bb-theme-color;
            font-size: 20px;
            font-weight: bold;
            .big-logo{
                max-width: 50px;
                margin-right: 10px;
            }
        }
    } 
    &:not(.help){
    @media only screen and (max-width: 480px){
        .chatlio-widget-body {
            width:calc(100% - 41px) !important;
            top: 12px;
            height: calc(100% - 20px - 64px - 48px) !important;
        }
    }
}
    &.help{
        .chatlio-chat-header__minimize-button{
            display: none !important;
        }
        .chatlio-widget{
                height: 400px;
                width: 400px;
                position: fixed;
                // left: 50%;
                right: calc(14% - 138px);
                z-index: 97;
                margin-top: -50px;
                margin-left: -100px;
            }
            .chatlio-title-bar {
                transition: all 0.3s;
                &.chatlio-title-bar-button{
                    &.chatlio-bottom-right{
                        display: none;
                    }
                }        
            }
            @media only screen and (max-width: 699px) {
                .chatlio-widget{
                 left: 25% !important;
                }
            }
            @media only screen and (max-width: 480px){
                .chatlio-widget-body {
                 bottom: 30px !important;
                 top:unset !important
                }
            }
            .chatlio-title-bar{  
                &.chatlio-title-bar-chip{
                    display: none;
                }
            }
    }
    .chatlio-title-bar {
        &.chatlio-title-bar-button{
            &.chatlio-bottom-right{
                position: absolute;
                right: 21px; 
                bottom: 40px;
                border: 2px $bb-budster-background-color solid;
                border-radius: 50% !important;
            }
        }        
    }
    .chatlio-powered-by{
        display: none !important;
    }
    .wrap-header{
        background-color: $bb-text-color-white;
    }
}

.chatlio-offline-form{
    @include horizontal-borders;
    border-radius: 0px 0px 0px 20px;
}

.online-dot-custom{
    position: absolute !important;
    bottom: 0 !important;
    right: 0px !important;
    left: auto !important;
    border: 2px solid #fff !important;
    width: 10px;
    height: 10px;
    background-color: #46c86d!important;
    border-radius: 10px;
    text-indent: -9999px;
    display: -webkit-inline-box;
    margin-left: -10px;
    margin-top: 20px;
    &.deal-status{
        position: relative !important;
        top: 0.6em;
        margin-top: 0;
        left: 10px !important;
    }
}

.chatlio-widget.chatlio-closed .chatlio-title-bar.chatlio-title-bar-button.chatlio-bottom-right{
    background: url(https://s3.ap-southeast-2.amazonaws.com/library.bigbuddy.io/assets/comments.svg) center center no-repeat !important;
    background-color: var( --bb-bg-color-tertiary) !important;

}
.chatlio-online-dot{
    margin-left: -2px !important;
    border: 3px solid black !important;
    margin-top: 40px !important;
}
.chatlio-widget-minimize{
    top: 49% !important;
    left: 49% !important;
}