
.budster-format-help-container{
    background: var(--bb-budster-background-color) !important;
    border-radius: 10px !important;
    width: 330px !important;
    opacity: 1 !important;
    &.w-198{
        width: 198px !important;
    }

    img{
        width: 64px;
        height: auto;
        align-self: center;
        border-radius: 50%;
        margin-right: 20px;
    }

    &:after, &:before{
        border-top-color: var(--bb-budster-background-color) !important;
        //display: none;
    }

}
.w-mx-content{
    width: max-content;
}    
