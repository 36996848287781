@import './react-table';
/* React table styles */

.bb-all-tables {
    font-size: 14px;
    border: none !important;

    .rt-thead.-header {
        box-shadow: none !important;
    }

    .rt-thead.-headerGroups {
        border-bottom: none !important;
    }

    .table-link{
        text-decoration: none;
        color: white !important;
    }

    &.no-hover {
        .rt-tr:not(.-padRow):hover {
            &.-odd {
                background: var(--bb-bg-color-tertiary);
            }

            &.-even {
                background: unset !important;
            }

            color: white !important;
            opacity: unset !important;

            a {
                color: white !important;

                i {
                    color: white !important;
                }
            }
        }
    }

    &.mx-500 {
        max-width: 500px !important;
    }
    &.mx-700 {
        max-width: 700px !important;
    }

    &.mx-250 {
        max-width: 250px !important;
    }


    .rt-thead {

        .rt-th,
        .rt-td {
            border-right: 1px solid rgba(0, 0, 0, 0.05);
        }

        .rt-th {
            white-space: inherit;
            font-weight: bold;
            color: var(--bb-text-color-white);
            text-align: -webkit-center !important;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            min-height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .rt-resizable-header-content {
            display: block !important;
            vertical-align: middle;
            text-align: -webkit-center;
            text-align: center;
            font-weight: bold;
        }
    }

    .rt-tbody {
        .rt-td {
            align-self: center;
            /* border: none !important; */
            border-right: 1px solid rgba(0, 0, 0, 0.05);

            &.text-overflow-none {
                text-overflow: unset;
            }

            &.text-grey{
                color: grey;
            }
        }

        .rt-tr-group {
            /* border: none !important;  */
            border-right: 1px solid rgba(0, 0, 0, 0.05);
        }


        .rt-tr:not(.-padRow):hover {
            background: var(--bb-bg-color-tertiary-30);
            opacity: 0.8;

            a {
                color: var(--bb-theme-color);

                i {
                    color: var(--bb-theme-color);
                }
            }
            .table-link{
                text-decoration: underline;
            }
        }
    }


    .rt-tr.-odd {
        background: var(--bb-bg-color-tertiary);
    }

    a {
        color: var(--bb-text-color-white);
        &:hover{
            text-decoration: none;;
        }
    }

    /*  a:hover{
        color: var(--bb-theme-color);
    } */
    .rt-expander:after {
        border-top: 7px solid var(--bb-color-white) !important;
    }

    input[type="checkbox"] {
        position: relative;
        cursor: pointer;
        box-shadow: none !important;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            top: -4px;
            left: -2px;
            border: 1px solid #252525;
            border-radius: 0px;
            background-color: var(--bb-bg-color-secondary);
        }

        &:checked:after {
            content: "";
            display: block;
            width: 8px;
            height: 16px;
            border: solid green;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 0px;
            left: 6px;
        }
    }
    .table-select-checkbox{
        input[type="checkbox"] {
            &:before {
                width: 16px;
                height: 16px;
                top: 0px;
                left: -1px;
            }
    
            &:checked:after {
                width: 6px;
                height: 11px;
                top: 1px;
                left: 4px;
            }
        }
    }

    .rt-noData {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        -webkit-transform: none !important;
        transform: none !important;
        background: transparent !important;
        margin: 0 auto;
        color: var(--bb-text-color-white) !important;
        pointer-events: none;
        padding: 20px;
        font-size: 24px;
    }

    .bb-sub-table-container {
        .info {
            div:nth-child(1) {
                width: 150px;
            }
        }
        .info-large {
            div:nth-child(1) { width: 200px; }
        }
    }

}


.bb-all-tables>.rt-table::-webkit-scrollbar {
    width: 1.25em;
    /* Total width including `border-width` of scrollbar thumb */
    height: 16px;
    box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
    // border-radius: 10px;
    margin-right: 20px;

    &-thumb {
        height: 1em;
        border: 0.3em solid rgba(0, 0, 0, 0);
        /* Transparent border together with `background-clip: padding-box` does the trick */
        background-clip: padding-box;
        -webkit-border-radius: 0.5;
        background-color: var(--bb-bg-color-tertiary-20);
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
    }

    &-track {
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
        //border-radius: 10px;
        padding: 10px;
    }
}


.bb-sub-table {
    font-size: 14px;
    border: none !important;
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;

    &-label {
        font-weight: bold;
        background: var(--bb-color-secondary);
    }

    &-container {
        display: flex;
        flex-direction: column;
        background: #261c35;
    }

    .rt-thead.-header {
        box-shadow: none !important;
    }

    .rt-thead.-headerGroups {
        border-bottom: none !important;
    }

    .rt-thead {

        .rt-th,
        .rt-td {
            border-right: 1px solid rgba(0, 0, 0, 0.05);
        }

        .rt-th {
            white-space: inherit;
            font-weight: bold;
            text-align: -webkit-center !important;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            color: var(--bb-text-color-white);
            padding: 0 !important;
        }

        .rt-resizable-header-content {
            display: table-cell;
            vertical-align: middle;
            height: 32px;
            text-align: -webkit-center;
            text-align: center;
            font-weight: bold;
        }

        .rt-tr:not(.-padRow):hover {
            background: transparent !important;
            color: var(--bb-text-color-white) !important;
            opacity: 1;

        }
    }

    .rt-tbody {
        border-bottom: 1px solid var(--bb-color-secondary);

        .rt-td {
            align-self: center;
            /* border: none !important; */
            border-right: 1px solid rgba(0, 0, 0, 0.05);
        }

        .rt-tr-group {
            /* border: none !important;  */
            border-right: 1px solid rgba(0, 0, 0, 0.05);
        }

        .rt-tr:not(.-padRow):hover {
            background: var(--bb-bg-color-secondary);
            color: var(--bb-theme-color);
            opacity: 0.8;

            a {
                color: var(--bb-theme-color);

                i {
                    color: var(--bb-theme-color);
                }
            }
        }
    }


    .rt-tr.-odd {
        background: var(--bb-bg-color-tertiary);
    }

    a {
        color: var(--bb-text-color-white);
    }

    /*  a:hover{
        color: var(--bb-theme-color);
    } */
}

.bt-table-paginations {
    &-buttons {
        background: var(--bb-bg-color-tertiary);
        padding: 0 5px;

        .bt-table-pagination-button {
            background: transparent;
            border: none;
            padding: 5px;

            &:disabled {
                i {
                    color: var(--bb-color-disabled) !important;
                }
            }
        }

    }

}

.bt-table-page-input {
    input {
        //max-width: 40px;
        text-align: right;
    }
}

.bb-no-datas {
    font-size: 20px;
    text-align: center;
    padding: 25px;
    border: none;
}

.row-selected{
    background-color: rgba(var(--bb-blue-color-rgb), .5) !important;
    color: var(--bb-text-color-white) !important;
}
.selectable-table-checkbox {
    position: relative;
    cursor: pointer;
    box-shadow: none !important;

    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: -2px;
        left: -2px;
        border: 1px solid #252525;
        border-radius: 0px;
        background-color: var(--bb-bg-color-secondary);
    }

    &:checked:after {
        content: "";
        display: block;
        width: 8px;
        height: 16px;
        border: solid green;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
    }
}

.bt-table-page-input {
    input {
        max-width: none;
    }
}

.bb-dsp-media-table {
    font-size: 14px;
    border: none !important;
    text-align: left;
    border-collapse: collapse;
    width: 100%;

    &.max-height {
        max-height: 250px;
    }

    input {
        font-size: 14px;
    }

    input[type="checkbox"] {
        position: relative;
        cursor: pointer;
        box-shadow: none !important;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 24px;
            height: 24px;
            top: -2px;
            left: -2px;
            border: 1px solid #252525;
            border-radius: 0px;
            background-color: var(--bb-bg-color-secondary);
        }

        &:checked:after {
            content: "";
            display: block;
            width: 8px;
            height: 16px;
            border: solid green;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 2px;
            left: 6px;
        }
    }

    .rt-thead.-header {
        box-shadow: none !important;
    }

    .rt-thead.-headerGroups {
        border-bottom: none !important;
    }

    .rt-thead {

        .rt-th,
        .rt-td {
            border-bottom: none !important;
            text-align: left;
            padding: 10px;
        }

        .rt-th {
            white-space: inherit;
            font-weight: bold;
            color: var(--bb-text-color-vendor);
            text-align: -webkit-center !important;
            //border-top: 1px solid rgba(0,0,0,0.05);
            background: var(--bb-theme-color);

            &.-sort-asc {
                box-shadow: none !important;

                &:before {
                    content: "\25BC";
                    float: right;
                    font-size: 10px;
                }
            }

            &.-sort-desc {
                &:before {
                    content: "\25B2";
                    float: right;
                    font-size: 10px;
                }
            }
        }

        .rt-td {
            &.-sort-asc {
                box-shadow: none !important;

                &:before {
                    content: "\25BC";
                    float: right;
                    font-size: 10px;
                }
            }

            &.-sort-desc {
                &:before {
                    content: "\25B2";
                    float: right;
                    font-size: 10px;
                }
            }
        }

        .rt-resizable-header-content {
            display: table-cell;
            vertical-align: middle;
            height: 32px;
            text-align: -webkit-center;
            text-align: center;
            font-weight: bold;
        }
    }

    .rt-tbody {
        .rt-td {
            white-space: normal;
            text-align: left;
            padding: 10px;
            background: var(--bb-bg-color-secondary);
            color: var(--bb-text-color-black);
            border-bottom: 0.5px solid #ddd !important;
        }

        .rt-tr-group {
            border-bottom: none !important;
        }

        .rt-tr:not(.-padRow) {
            background: var(--bb-bg-color-secondary);
        }

        .rt-tr:not(.-padRow):hover {
            background: var(--bb-bg-color-secondary);
            color: var(--bb-theme-color);
            opacity: 0.8;

            a {
                color: var(--bb-theme-color);

                i {
                    color: var(--bb-theme-color);
                }
            }
        }
    }

    .rt-foot {
        .rt-tr {
            font-weight: bold;
        }
    }

    .rt-noData {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        -webkit-transform: none !important;
        transform: none !important;
        background: transparent !important;
        margin: 0 auto;
        color: var(--bb-text-color-white) !important;
        pointer-events: none;
        padding: 20px;
    }

    /* 
    .rt-tr.-odd{
        background: var(--bb-bg-color-tertiary);
    }    */
    .rt-tr.-disabled {
        .rt-td {
            background: lightgray !important;
        }
    }

    a {
        color: var(--bb-text-color-white);
    }
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 0 3px;
    background: transparent;
    border-bottom: solid 7px var(--bb-color-white);
    border-top-width: 0;
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 5px transparent;
    margin: 4px 4px 0 3px;
    background: transparent;
    border-top: solid 7px var(--bb-color-white);
    border-bottom-width: 0;
}

@media (max-width: 430px) {
    .justify-phone-right {
        justify-content: flex-end !important;
    }
}

.bt-table-paginations-container {
    &.mx-500 {
        max-width: 500px !important;
    }
}

.bb-all-header-filter {
    min-width: 130px;
    border: none;
    font-size: 12px;
    border-radius: 5px;
    outline: none;
}

.bb-dsp-media-table-container {
    max-height: 250px;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 1.25em;
        /* Total width including `border-width` of scrollbar thumb */
        height: 16px;
        box-shadow: inset 0 0 100px var(--bb-bg-color-tertiary);
        // border-radius: 10px;
        margin-right: 20px;

        &-thumb {
            height: 1em;
            border: 0.3em solid rgba(0, 0, 0, 0);
            /* Transparent border together with `background-clip: padding-box` does the trick */
            background-clip: padding-box;
            -webkit-border-radius: 0.5;
            background-color: var(--bb-bg-color-tertiary-20);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
        }

        &-track {
            -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
            box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.025);
            //border-radius: 10px;
            padding: 10px;
        }
    }
}

.buddy_light {
    &.bb-all-tables {
        .rt-thead {
            &.-header {
                &, .rt-th{ background: var(--bb-primary-background-0); }
                width: 100%;
            }

            .rt-th {
                .rt-resizable-header-content {
                    font-weight: 100;
                }

                &.-sort-asc {
                    .rt-resizable-header-content {
                        font-weight: bold !important;
                    }

                    .rt-resizer:before {

                        border-bottom: solid 7px var(--bb-text-color-black);
                    }
                }

                &.-sort-desc {
                    .rt-resizable-header-content {
                        font-weight: bold !important;
                    }

                    .rt-resizer:before {

                        border-top: solid 7px var(--bb-text-color-black);
                    }
                }

                color: var(--bb-text-color-black);
                border: 1px solid rgba(0, 0, 0, 0.05);
            }

        }

        .rt-tbody {
            .indicate_nay {
                position: absolute;
                width: 7px;
                height: 7px;
                top: 7px;
                left: 7px;
                background-color: var(--bb-buddy-nay-1);
            }

            .indicate_oops {
                position: absolute;
                width: 7px;
                height: 7px;
                top: 7px;
                left: 7px;
                background-color: var(--bb-buddy-oops-1);
            }

            .rt-tr-group:last-child {
                border-bottom: solid 1px rgba(0, 0, 0, 0.05);
                border-right: solid 1px rgba(0, 0, 0, 0.05);
                border-left: solid 1px rgba(0, 0, 0, 0.05);

            }
           

            .rt-tr {
                border-bottom: 1px solid var(--bb-primary-background-0);
                &.-even {
                    background-color: var(--bb-primary-background-1);

                }

                &.-odd {
                    background-color: white;

                }

                &:not(.-padRow):hover {
                    background: var(--bb-primary-background-05) !important;
                    opacity: 1;
                }
            }

        }
        .rt-tfoot{
            .rt-tr {
                background-color: var(--bb-primary-background-1);
                border-bottom: 1px solid var(--bb-primary-background-0);
            }
        }

    }

}
.white-space-unset{
    white-space: unset !important;
}
.table-th-left .rt-th div{ justify-content: flex-start !important; }
.d-inline-table{ display: inline-table !important; }
.subtable-row{
    &.subtable-expanded{
        border-bottom: 1px solid rgba(202, 203, 211, 0.20);
    }
}
.number-col{ text-align: right !important; justify-content: right !important;}

.rt-tr .button-hovers{ opacity: 0; pointer-events: none; }
.rt-tr:hover .button-hovers{ opacity: 1; pointer-events: all; }