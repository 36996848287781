
:root {
    --bb-theme-color: #E83457;
    --bb-theme-color-rgb: 232, 52, 87;
    --bb-text-color: #E83457;
    --bb-text-color-vendor: #FFF;
    --bb-bg-color: #1C2135;

    --bb-small-logo: url("'~/public/images/buddyLogo.svg");
    --bb-small-logo-unified: url("'~/public/images/budenterprise/budenterprise_unified.svg");
    --bb-small-logo-independent: url("'~/public/images/budenterprise/budenterprise_independent.svg");
    --bb-small-logo-enterprise: url("'~/public/images/budenterprise/budenterprise.svg");

    --bb-theme-color-secondary: #19A4CF; 
  
    --bb-bg-color-secondary: #F5F5F5; /* Whitesmoke */
    --bb-bg-color-tertiary: #2E3547;
    --bb-bg-color-tertiary-20: rgba(202, 203, 211, 0.2);
    --bb-bg-color-tertiary-30: rgba(202, 203, 211, 0.3);
    --bb-bg-color-gray: #858585;
    --bb-bg-color-quaternary: #2A3143;
    --bb-bg-color-quaternary-50: #2A314380;

    --bb-color-disabled: #858585;
    --bb-color-secondary: #19A4CF;
    --bb-color-warning: #F2994A;
    --bb-color-error: #EB5757;
    --bb-color-gray: #CCC;
    --bb-color-white: #FFF;
    --bb-color-black: #000;

    --bb-purple: #8D34FE;
    --bb-green-50: #E9F3DF;
    --bb-green-100: #D3E7BF;
    --bb-green-200: #BEDC9F;
    --bb-green-300: #A8D080;
    --bb-green-400: #92C460;
    --bb-green-500: #81B54C;
    --bb-green-600: #6D9E3B;
    --bb-green-700: #5D8832;
    --bb-green-800: #4E712A;
    --bb-green-900: #3E5B21;
    --bb-green: #6D9E3B;
    
    --bb-blue-50: #D6F1FA;
    --bb-blue-100: #B3EDFF;
    --bb-blue-200: #83D6F0;
    --bb-blue-300: #5AC9EB;
    --bb-blue-400: #30BBE6;
    --bb-blue-500: #19A4CF;
    --bb-blue-600: #1690B5;
    --bb-blue-700: #137B9B;
    --bb-blue-800: #106681;
    --bb-blue-900: #0C5268;

    --bb-color-semi-muted: #888DA0;
    --bb-color-white-secondary: #D5D5D5;

    --bb-text-color-white: #FFF;
    --bb-text-color-black: #000;
    --bb-text-color-gray: #CCC;
    --bb-text-color-blue: #08b9db;
    --bb-text-color-grey-10: #959BB3;
    /* Blue Buddy */
    --bb-blue-color: #365B9C;
    --bb-blue-color-secondary: #08b9db;
    --bb-blue-color-rgb: 54, 91, 156;

    /* Budster */
    --bb-budster-background-color: #484D5C;
    --bb-budster-border-color: #484D4C;
    /* e11c5e #E83457*/
  }
  